const date = new Date();
const now = date.toISOString();
date.setMinutes(date.getMinutes() - 30);
const thirtyMinutesAgo = date.toISOString();
date.setDate(date.getDate() - 1);
const oneDayAgoString = date.toISOString();
date.setMinutes(date.getMinutes() - 30);
const oneDay30MinutesAgoString = date.toISOString();


export const trips = [
  {
    tripId: 10787207,
    programName: "Indego",
    checkOutDate: now,
    checkOutLocation: "11th & Pine, Kahn Park",
    checkOutLat: 39.94517,
    checkOutLon: -75.15993,
    checkInDate: "0001-01-01T00:00:00Z",
    checkInLocation: null,
    checkInLat: 0.0,
    checkInLon: 0.0,
    miles: 0.00000,
    cost: 0.0,
    moneySaved: 0.00000,
    hasBikeComputerData: false,
    duration: 0,
    isDurationAdjusted: false,
    programId: 3,
    isReturnLocationMissing: false,
    isCheckoutLocationMissing: false,

  },
  {
    tripId: 10787206,
    programName: "Indego",
    checkOutDate: thirtyMinutesAgo,
    checkOutLocation: "11th & Reed",
    checkOutLat: 39.93315,
    checkOutLon: -75.16248,
    checkInDate: now,
    checkInLocation: "11th & Pine, Kahn Park",
    checkInLat: 39.94517,
    checkInLon: -75.15993,
    miles: 1.49999,
    cost: 2.16,
    moneySaved: 1.84499,
    hasBikeComputerData: false,
    duration: 10,
    isDurationAdjusted: false,
    programId: 3,
    isReturnLocationMissing: false,
    isCheckoutLocationMissing: false,
  },
  {
    tripId: 10786909,
    programName: "Indego",
    checkOutDate: oneDay30MinutesAgoString,
    checkOutLocation: "11th & Pine, Kahn Park",
    checkOutLat: 39.94517,
    checkOutLon: -75.15993,
    checkInDate: oneDayAgoString,
    checkInLocation: "12th & Passyunk",
    checkInLat: 39.92814,
    checkInLon: -75.16515,
    miles: 2.39999,
    cost: 3.456,
    moneySaved: 2.95199,
    hasBikeComputerData: false,
    duration: 16,
    isDurationAdjusted: false,
    programId: 3,
    isReturnLocationMissing: false,
    isCheckoutLocationMissing: false,
  },
];