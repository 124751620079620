export default {
  keyCard: "key card",
  userAgreement: {
    unattended: "I agree never to leave a bike unattended when not securely docked in a station.",
    replacement: "I understand that if the bike is lost or stolen, I will be charged a bike replacement fee of up to $2,500.",
    eula: "I am 16 years old or older, and I have read and agree to the conditions set forth in the <a href=\"https://bikeshare.rtcsnv.com/user-agreement/\" target=\"_blank\">User Agreement</a>",
  },
  demographicsSurvey: {
    preLinkText: "Please take our ",
    linkText: "demographics survey.",
  },
  reducedFare: {
    sectionDescription: "Nevada EBT cardholders qualify for discounted annual and monthly passes",
  },
};