import { defineStore, acceptHMRUpdate } from "pinia";
import { storeOptions } from "./storeOptions";
import { mockUseAccountOptions } from "../../test/mockAccount";

export const fakeStore = defineStore("mockAccount", mockUseAccountOptions);
const realStore = defineStore("account", storeOptions);

export const useAccount = import.meta.env.VITE_MOCK_USE_ACCOUNT && window.location.href?.includes("mock=true") ? fakeStore : realStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccount, import.meta.hot));
}
