<template>
  <ReducedFareValidation>
    <template
      #summary
    >
      <p>
        Per the United States federal government's <ExternalLink
          href="https://www.ada.gov/topics/intro-to-ada/"
          text="ADA.gov website"
        />
        &nbsp;a person with a disability is someone who:
      </p>
      <ul class="bulleted">
        <li>
          has a physical or mental impairment that substantially limits one or more major life activities,
        </li>
        <li>
          has a history or record of such an impairment (such as cancer that is in remission), or
        </li>
        <li>
          is perceived by others as having such an impairment (such as a person who has scars from a severe burn).
        </li>
      </ul>
      <p>If a person falls into any of these categories, the ADA protects them. Because the ADA is a law, and not a benefit program, you do not need to apply for coverage.</p>
    </template>
    <template #eligibility-fields>
      <fieldset class="fieldset">
        <legend class="legend legend--smaller">
          Self-certify disability status
        </legend>
        <o-checkbox
          :id="selfCertErrorProps.inputProps.id"
          v-model="account.cart.discountVerificationForm.disability.selfCert"
          type="checkbox"
          :variant="selfCertErrorProps.fieldProps.variant"
          required
          autofocus
        >
          {{ selfCertErrorProps.fieldProps.label }}
        </o-checkbox>
        <o-checkbox
          :id="proofErrorProps.inputProps.id"
          v-model="account.cart.discountVerificationForm.disability.agreeToProve"
          type="checkbox"
          :variant="proofErrorProps.fieldProps.variant"
          required
        >
          {{ proofErrorProps.fieldProps.label }}
        </o-checkbox>
      </fieldset>
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { onUnmounted } from "vue";
import { useAccount } from "../../../../stores/account";
import ExternalLink from "../../../ExternalLink.vue";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();

const selfCertErrorProps = useFieldErrorProvider(() => account.cart.discountVerificationForm.disability.selfCert, "disabilitySelfCert");
const proofErrorProps = useFieldErrorProvider(() => account.cart.discountVerificationForm.disability.agreeToProve, "disabilityProof");

onUnmounted(() => {
  selfCertErrorProps.clearErrors();
  proofErrorProps.clearErrors();
});
</script>
