<template>
  <SkipNav focus-el-i-d="account-app-main" />
  <nav
    id="mock-account"
    :class="{ live: !route.query.mock }"
    aria-label="Testing controls"
  >
    <div class="mock-controls">
      <div
        v-if="route.query.mock"
        class="o-field"
      >
        Using mock data.
        &nbsp;<router-link
          :to="{ name: 'login', query: {} }"
          target="_blank"
          rel="noopener noreferrer"
        >
          Use live data
        </router-link>
      </div>
      <div
        v-else
        class="o-field"
      >
        Caution: modifying live data.
        &nbsp;<router-link
          :to="{ name: 'home', query: { mock: true }}"
          target="_blank"
          rel="noopener noreferrer"
        >
          Use mock data
        </router-link>
      </div>
      <o-field>
        <o-switch v-model="account.isEmbedded">
          Embedded
        </o-switch>
      </o-field>
      <o-field
        label="System"
        label-for="system-select"
      >
        <o-select
          id="system-select"
          v-model="account.system"
        >
          <option
            v-for="{ name }, system in systemData"
            :key="system"
            :value="system"
          >
            {{ name.replace('Bike Share', '') }}
          </option>
        </o-select>
      </o-field>
      <o-field
        v-if="route.query.mock && account.system"
        label="Pass Type"
        label-for="pass-select"
      >
        <o-select
          id="pass-select"
          v-model="selectedPassTypeId"
        >
          <option value="none">
            No Pass/Pass Not Found
          </option>
          <option
            v-for="passType in account.currentSystem.passTypes"
            :key="passType.id"
            :value="passType.id"
          >
            {{ `${passType.name}${passType.family === PASS_FAMILY.restricted ? '*' : ''}` }}
          </option>
        </o-select>
      </o-field>
      <o-field
        v-if="route.query.mock && account.system"
        label="Pass State"
        label-for="state-select"
      >
        <o-select
          id="state-select"
          v-model="selectedAccountStateId"
        >
          <option
            v-for="state in accountStates"
            :key="state.id"
            :value="state.id"
          >
            {{ state.name }}
          </option>
        </o-select>
      </o-field>
      <o-field
        v-if="route.query.mock && account.system"
        class="not-lax"
      >
        <o-switch
          v-model="account.keyCard"
          class="capitalize"
        >
          {{ t('keyCard') }}
        </o-switch>
      </o-field>
    </div>
  </nav>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SkipNav from "../src/components/SkipNav.vue";
import { useAccount } from "../src/stores/account";
import { getPassStatesForPassType } from "./data";
import { PASS_FAMILY, systemData } from "../src/systemData";
import { userKeyCard } from "./data/userKeyCard";

const account = useAccount();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const selectedPassTypeId = ref(account.user?.subscriptionTypeId ?? account.currentSystem?.passTypes?.[0]?.id);
const selectedPassType = computed(() => account.getPassType(selectedPassTypeId.value));

const accountStates = computed(() => getPassStatesForPassType(selectedPassType.value, account.system));
const selectedAccountStateId = ref(account.id ?? accountStates.value?.[0]?.id);
const selectedAccountState = computed(() => accountStates.value ? accountStates.value.find(state => state.id === selectedAccountStateId.value) : null);
const keyCard = computed(() => account.keyCard);

watch(() => account.system, (_, oldSystem) => {
  if (!account.system || !route.query.mock) {
    return;
  }
  // Try to show the most comparable pass from the new system
  const oldPass = systemData[oldSystem]?.passTypes
    .find(p => p.id === selectedPassTypeId.value);
  const defaultSelectedPass = account.currentSystem.passTypes
    .find(p =>
      p.duration === oldPass?.duration &&
      !!p.discountVerification === !!oldPass?.discountVerification &&
      p.family === oldPass?.family,
    ) || account.currentSystem.passTypes[0];

  selectedPassTypeId.value = defaultSelectedPass.id;
  updateAccountState();
  router.go();
});

watch(selectedPassTypeId, () => {
  selectedAccountStateId.value = accountStates.value[0].id;
  updateAccountState();
});

watch(selectedAccountStateId, updateAccountState);
watch(keyCard, updateAccountState);

function updateAccountState() {
  const newState = { ...selectedAccountState.value, system: account.system, isEmbedded: account.isEmbedded, userKeyCard: keyCard.value ? userKeyCard : undefined };
  account.$patch(newState);
  if (route.query.step) {
    // If someone is in the change flow, set them back to the first step to avoid erroring out
    router.push({...route, query: { ...route.query, step: "" }});
  }
}
</script>

<style lang="scss">
@import "../assets/media-queries.scss";

#mock-account {
  font-size: 0.75rem;
  background-color: var(--secondary);
  font-weight: bold;

  &.live {
    background-color: var(--error);
    color: white;

    a {
      color: inherit;
    }
  }

  .mock-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media("screen", "<tablet") {
      flex-direction: row;
      justify-content: center;
      align-items: start;
      flex-wrap: wrap;
    }

    .o-field {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 0.5em 16px;

      &__label {
        margin-right: 0.5em;
        font-size: inherit;
        color: inherit;
      }
      
      @include media("screen", "<tablet") {
        margin: 0.25rem 0.5rem 0.25rem;
        justify-content: left;
        align-self: center;
      }

      .o-sel {
        font-size: inherit;
      }
    }
  }
}
</style>