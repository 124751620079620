export const PASS_DURATION = {
  single: 0.1,
  day: 1,
  threeDays: 3,
  week: 7,
  month: 30,
  fiveMonths: 5 * 30,
  sixMonths: 6 * 30,
  year: 365,
};

export const PASS_FAMILY = {
  none: "none",
  casual: "casual",
  member: "member",
  restricted: "restricted",
};

export const PURCHASE_TYPES = {
  new: "new user pass purchase",
  renew: "renewal",
  change: "pass change",
};