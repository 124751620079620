const today = new Date();
const thisYear = today.getFullYear();
const thisMonth = today.getMonth();
const lastMonth = thisMonth - 1 ?? 12;
const lastMonthYear = lastMonth === 1 ? thisYear - 1 : thisYear;

const thisMonthCharges = [
  {
    itemType: "Charge",
    itemName: "Ebike Trip Increment",
    assessedDate: `${thisYear}-${thisMonth}-01T10:14:38.44Z`,
    programName: "Indego",
    itemAmount: 2.376,
  },
  {
    itemType: "Charge",
    itemName: "Ebike Trip Increment",
    assessedDate: `${thisYear}-${thisMonth}-11T10:13:39.353Z`,
    programName: "Indego",
    itemAmount: 3.456,
  },
];

const lastMonthCharges = [
  {
    itemType: "Charge",
    itemName: "Membership Fee",
    assessedDate: `${lastMonthYear}-${lastMonth}-03T17:40:24.46Z`,
    programName: "Indego",
    itemAmount: 21.6,
  },
  {
    itemType: "Charge",
    itemName: "Ebike Trip Increment",
    assessedDate: `${lastMonthYear}-${lastMonth}-11T11:55:30.007Z`,
    programName: "Indego",
    itemAmount: 6.48,
  },
];

// This matches the format in account state, as processed by fetchStatements in storeOptions
const statements = {};
statements[`${thisYear}-${thisMonth}`] = thisMonthCharges;
statements[`${lastMonthYear}-${lastMonth}`] = lastMonthCharges;

export { statements };