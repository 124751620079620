import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { createPinia } from "pinia";
import {
  Table,
  Button,
  Checkbox,
  Loading,
  Tabs,
  Datepicker,
  Field,
  Input,
  Select,
  Collapse,
  Steps,
  Radio,
  Notification,
  Switch,
  Dropdown,
} from "@oruga-ui/oruga-next";
import HoneybadgerVue from "@honeybadger-io/vue";
import "../assets/style.scss";
import "@stripe/stripe-js";
import { setupI18n } from "./i18n";

export const i18n = setupI18n();

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(Table)
  .use(Button)
  .use(Checkbox)
  .use(Dropdown)
  .use(Loading)
  .use(Tabs)
  .use(Datepicker)
  .use(Input)
  .use(Field)
  .use(Collapse)
  .use(Select)
  .use(Steps)
  .use(Radio)
  .use(Notification)
  .use(Switch);

if (import.meta.env.VITE_HONEYBADGER_API_KEY) {
  app.use(HoneybadgerVue, {
    apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
    environment: "production",
    revision: import.meta.env.VITE_HONEYBADGER_REVISION,
  });
}

app.mount("#app");
