// This file must be separate from account.js to avoid circular dependency between account.js and mockAccount.js
import { $fetch } from "ohmyfetch";
import { useStorage } from "@vueuse/core";
import { PASS_FAMILY, systemData } from "../systemData";
import { metaPixelTrackPurchase, sendAppMessage } from "../utils";
import { notEligible } from "../components/ReducedFare/reducedFareTypes";
import { stateDefaults } from "./stateDefaults";

const MIN_TRIPS_FOR_RATING = 3;

export const getInitState = () => ({
  // https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy
  ...JSON.parse(JSON.stringify(stateDefaults)),
  system: useStorage("system", null),
  accessToken: useStorage("accessToken", null),
  isEmbedded: useStorage("isEmbedded", false),
});

const cloudflareAPI = import.meta.env.VITE_WP_EMBED
  ? "https://account.bicycletransit.com/api"
  : "/api";

export const storeOptions = {
  state: getInitState,
  getters: {
    currentSystem() {
      return systemData[this.system];
    },
    getPassTypes() {
      return function ({
        visibleOnly,
        autoRenewing,
        reducedFareOnly,
        standardFareOnly,
        reducedWhereAvailable,
      }) {
        return this.currentSystem.passTypes
          .filter(({ visible, isAutoRenewing, discountVerification, family }) => {
            let returnPass = true;
            if (visibleOnly) {
              // Visible might be not be specified, and undefinied is assumed true
              returnPass = visible !== false;
              if (typeof visible === "function") {
                returnPass = visible(this);
              }
            }
            if (typeof autoRenewing !== "undefined") {
              returnPass = returnPass && isAutoRenewing === autoRenewing;
            }

            if (reducedFareOnly) {
              returnPass = returnPass && !!discountVerification;
            } else if (standardFareOnly) {
              returnPass = returnPass && !discountVerification;
            } else if (reducedWhereAvailable) {
              // Assumption that all reduced fare passes are membership passes
              // and that riders still might want to see casual memberships
              returnPass = returnPass && (!!discountVerification || family === PASS_FAMILY.casual);
            }

            return returnPass;
          })
          .sort((a, b) => a.duration - b.duration);
      };
    },
    getPassType() {
      return (id) => this.currentSystem?.passTypes.find((type) => type.id === id);
    },
    currentPassType() {
      // if (!this.user) { this.fetchUser(); return; }
      return this.getPassType(this.user?.subscriptionTypeId);
    },
    currentPassIsReducedFare() {
      // if (!this.user) { this.fetchUser(); return; }
      return this.getPassTypes({ reducedFareOnly: true })
        .map((passType) => passType.id)
        .includes(this.user?.subscriptionTypeId);
    },
    getCurrentSubscriptions() {
      // It is technically possible for a user to have more than one current subscription
      // That's a weird edge case though
      return function ({ passes = false } = { passes: false }) {
        if (!this.userSummary) {
          this.fetchUserSummary();
          return [];
        }
        const now = new Date();
        let currentSubscriptions = this.userSummary.subscriptions.filter(
          (s) => new Date(s.startDate) < now && new Date(s.endDate) > now,
        );
        if (passes) {
          currentSubscriptions = currentSubscriptions.map((s) =>
            this.getPassType(s.subscriptionTypeId),
          );
        }
        return currentSubscriptions;
      };
    },
    getUpcomingPasses() {
      return function ({ dedupe = true } = { dedupe: true }) {
        if (!this.userSummary) {
          this.fetchUserSummary();
        }
        if (!this?.userSummary?.subscriptions?.length) {
          return [];
        }
        // Filter out past and currently active subscriptions
        const notYetStartedSubscriptions = JSON.parse(
          JSON.stringify(this.userSummary.subscriptions),
        ).filter((s) => !s.startDate || new Date(s.startDate) > new Date());

        if (!dedupe) {
          return notYetStartedSubscriptions;
        }

        // Dedupe and alter name to include number if more than one
        return notYetStartedSubscriptions.reduce(
          (accumulator, subscription, idx, allSubscriptions) => {
            const firstOfItsKind =
              idx ===
              allSubscriptions.findIndex(
                (s) => s.subscriptionTypeId === subscription.subscriptionTypeId,
              );

            let occurrences = allSubscriptions.filter(
              (s) => s.subscriptionTypeId === subscription.subscriptionTypeId,
            ).length;

            const sameTypeAsCurrentNotYetStarted =
              this.currentSubscriptionNotYetActivated &&
              subscription.subscriptionTypeId === this.user.subscriptionTypeId;

            // If a user's current subscription is a not yet activated casual pass
            // then we don't want to show the current subscription in the count of additional passes
            if (sameTypeAsCurrentNotYetStarted) {
              occurrences -= 1;
            }

            if (firstOfItsKind && occurrences) {
              const passType = this.getPassType(subscription.subscriptionTypeId);
              let name = passType.name;
              if (occurrences > 1) {
                name = `${name} (×${occurrences})`;
              }
              accumulator.push({
                ...passType,
                name,
              });
            }
            return accumulator;
          },
          [],
        );
      };
    },
    accountExpirationDate() {
      if (!this.userSummary) {
        this.fetchUserSummary();
        return;
      }
      return this.userSummary.membershipExpiration;
    },
    currentSubscriptionExpirationDate() {
      // if (!this.user) { this.fetchUser(); return; }
      if (!this.userSummary) {
        this.fetchUserSummary();
        return;
      }
      const currentSubscriptions = this.getCurrentSubscriptions();
      if (!currentSubscriptions.length) return;
      return currentSubscriptions[0].endDate;
    },
    currentSubscriptionActive() {
      // if (!this.user) { this.fetchUser(); }
      return this.user?.subscriptionActivationStatus?.toLowerCase() === "active";
    },
    currentSubscriptionExpired() {
      // if (!this.user) { this.fetchUser(); }
      return this.user?.subscriptionActivationStatus?.toLowerCase() === "expired";
    },
    currentSubscriptionNotYetActivated() {
      // if (!this.user) { this.fetchUser(); }
      return this.user?.subscriptionActivationStatus === "NotActivated";
    },
    cartDiscountTypeIsSet() {
      return this.cart?.discountType !== notEligible.type;
    },
    passInCart() {
      return this.getPassType(this.cart.subscriptionTypeId);
    },
    passInCartIsReducedFare() {
      return this.passInCart?.discountVerification;
    },
    totalUnpaidFees() {
      if (!this.unpaidFees) {
        return null;
      }
      let totalFees = 0;
      this.unpaidFees.forEach((fee) => {
        if (fee.feeType !== "Subscription") {
          totalFees += fee.adjustedCharge ? fee.adjustedCharge : fee.charge;
        }
      });
      return totalFees;
    },
    mustUpdateCard() {
      if (!this.paymentProfile || this.paymentProfile.cardType === "None") {
        return "Please add a new card to continue";
      }
      if (this.paymentProfile.paymentProcessorType !== "Stripe") {
        return "Please reenter your card to continue";
      }
      const cardExpirationDate = new Date(
        `${this.paymentProfile.cardExpirationYear}/${this.paymentProfile.cardExpirationMonth}/01`,
      );
      const now = new Date();
      if (now > cardExpirationDate) {
        return "Your card has expired. Please add a new card to continue";
      }
      return null;
    },
    createdDate() {
      if (!this.userSummary || !this.userSummary.memberSince) {
        return this.currentSystem.launchDate;
      }
      return new Date(this.userSummary.memberSince);
    },
    latestTrips() {
      let trips = [];
      const now = new Date();
      const thisMonth = this.trips[`${now.getFullYear()}-${now.getMonth() + 1}`];
      if (thisMonth) {
        trips.push(...thisMonth);
      }

      // if there are not a lot of trips, load the previous month
      if (trips.length < MIN_TRIPS_FOR_RATING) {
        now.setMonth(now.getMonth() - 1);
        const lastMonth = this.trips[`${now.getFullYear()}-${now.getMonth() + 1}`];
        if (lastMonth) {
          trips.push(...lastMonth);
        }
      }

      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      // return the latest trips, plus any additional trips within the last 24 hours
      return [
        ...trips.slice(0, MIN_TRIPS_FOR_RATING),
        ...trips
          .slice(MIN_TRIPS_FOR_RATING)
          .filter((trip) => new Date(trip.checkOutDate) > oneDayAgo),
      ];
    },
  },
  actions: {
    async login(username, password) {
      if (!this.system || Object.keys(systemData).findIndex((s) => s === this.system) === -1) {
        throw new Error("Bike share system not found");
      }
      const response = await $fetch(`${cloudflareAPI}/login`, {
        method: "POST",
        body: {
          system: this.system,
          username,
          password,
        },
      });
      if (response.access_token) {
        this.accessToken = response.access_token;
      }
      return response;
    },
    async logout() {
      this.$reset();
      this.accessToken = null;
    },
    async fetchBcycle(endpoint, options, authorized = true) {
      if (!authorized) {
        return this.fetchWithoutAuth(`${this.currentSystem.bcycleApi}/${endpoint}`, options);
      }
      return this.fetchWithAuth(`${this.currentSystem.bcycleApi}/${endpoint}`, options);
    },
    async fetchBts(endpoint, options) {
      return this.fetchWithAuth(`${this.currentSystem.btsApi}/${endpoint}`, options);
    },
    async fetchWithAuth(url, options = {}) {
      if (!this.accessToken) {
        // Logic to go back to login is in app commponent
        // No need to keep trying requests and throwing errors
        console.error("User is not logged in");
        return;
      }
      try {
        let response = await $fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${this.accessToken}`,
          },
        });
        return response;
      } catch (error) {
        if (error?.response?.status === 401) {
          this.accessToken = null;
          // Logic to go back to login is in app commponent
        } else {
          throw error;
        }
      }
    },
    async fetchWithoutAuth(url, options = {}) {
      return $fetch(url, {
        ...options,
        headers: {
          ...options.headers,
        },
      });
    },
    async fetchUnpaidFees() {
      const response = await this.fetchBts("user/unpaid-fees");
      if (typeof response.data !== "undefined") {
        this.unpaidFees = response.data;
      }
      return this.unpaidFees;
    },
    async fetchRewardPoints() {
      const response = await this.fetchBts("rewards/points/");
      if (typeof response !== "undefined") {
        this.rewardPoints = response;
      }
      return this.rewardPoints;
    },
    async fetchRewardOptions() {
      const response = await this.fetchBts("rewards/available/");
      if (typeof response !== "undefined") {
        this.rewardOptions = response;
      }
      return this.rewardOptions;
    },
    async redeemReward(request) {
      return this.fetchBts("rewards/redeem/", {
        method: "POST",
        body: request,
      });
    },
    async fetchPaymentProfile() {
      try {
        const response = await this.fetchBcycle("1/user/paymentProfile");
        this.paymentProfile = response;
        return response;
      } catch (error) {
        console.error(error);
        throw new Error(
          "Error fetching payment profile. Please try again or contact customer service.",
        );
      }
    },
    async fetchFrequentedStations() {
      try {
        const response = await this.fetchBcycle("1/user/frequentedkiosks");
        this.frequentedStations = response;
        return response;
      } catch (error) {
        console.error(error);
        // throw new Error("Error fetching frequented stations. Please try again or contact customer service.");
      }
    },
    async fetchUser() {
      try {
        const response = await this.fetchBcycle("1/user");
        this.user = response;
        return response;
      } catch (error) {
        throw new Error(
          "Error fetching user information. Please try again or contact customer service.",
        );
      }
    },
    async updateUser(newUserDetails) {
      // TODO: do we need to strip whitespace from the zip code, like in account portal registration?
      try {
        const response = await this.fetchBcycle("1/user", {
          method: "PUT",
          body: { ...this.user, ...newUserDetails },
        });
        await this.fetchUser();
        return response;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Error updating user information. Please try again or contact customer service.",
        );
      }
    },
    async updatePassword(newUserDetails) {
      try {
        await this.fetchBcycle("1/user", {
          method: "PATCH",
          body: newUserDetails,
        });
      } catch (e) {
        throw new Error(
          "Error updating user information. Please try again or contact customer service.",
        );
      }
    },
    async resetPassword(contactEmail) {
      return this.fetchWithoutAuth(`${cloudflareAPI}/resetPassword`, {
        method: "POST",
        body: {
          system: this.system,
          contactEmail,
        },
      });
    },
    async fetchUserSummary() {
      try {
        const response = await this.fetchBcycle("1/user/summary");
        // fix date format
        response?.subscriptions?.forEach((subscription) => {
          if (subscription.startDate && !subscription.startDate.endsWith("Z")) {
            subscription.startDate = subscription.startDate + "Z";
          }
          if (subscription.endDate && !subscription.endDate.endsWith("Z")) {
            subscription.endDate = subscription.endDate + "Z";
          }
        });
        this.userSummary = response;
        return response;
      } catch (error) {
        console.error(error);
        throw new Error(
          "Error fetching user information. Please try again or contact customer service.",
        );
      }
    },
    async fetchTrips({ year, month }) {
      try {
        const response = await this.fetchBcycle(`1/user/trips?month=${month}&year=${year}`);
        // show most recent first
        this.trips[`${year}-${month}`] = response?.sort(
          (a, b) => new Date(b.checkOutDate) - new Date(a.checkOutDate),
        );
        return response;
      } catch (error) {
        console.error(error);
        throw new Error(
          "Error fetching trip information. Please try again or contact customer service.",
        );
      }
    },
    async fetchStatements({ year, month }) {
      try {
        const response = await this.fetchBcycle(`1/user/statements?month=${month}&year=${year}`);
        // show most recent first
        this.statements[`${year}-${month}`] = response.sort(
          (a, b) => new Date(b.checkOutDate) - new Date(a.checkOutDate),
        );
        return response;
      } catch (error) {
        console.error(error);
        throw new Error("Error fetching statements. Please try again or contact customer service.");
      }
    },
    async updatePaymentProfile(token) {
      if (!this.accessToken) {
        // If the user is not logged in, then this should just update it locally
        this.paymentProfile = { stripeToken: token };
        return;
      }
      await this.fetchBcycle("1/stripe/paymentprofile", {
        method: "PUT",
        body: {
          tokenId: token.id,
        },
      });
      await this.fetchPaymentProfile();
    },
    async settleDelinquency() {
      if (!this.user) {
        await this.fetchUser();
      }
      if (!this.user.userId) {
        throw new Error("User ID not found");
      }
      return this.fetchBcycle(
        `1/user/settledelinquency?userId=${this.user.userId}&programId=${this.currentSystem.programId}`,
        {
          method: "PUT",
        },
      );
    },
    async validatePromoCode() {
      let user = this.user;
      if (!this.user?.contactEmail) {
        user = this.cart.newUser;
      }
      if (!user?.contactEmail) {
        return "Promo code could not be validated";
      }
      let type = "new";
      if (this.cart?.subscriptionTypeId === this.user?.subscriptionTypeId) {
        type = "renewal";
      }
      const response = await this.fetchWithoutAuth(`${cloudflareAPI}/promocode`, {
        method: "POST",
        body: {
          promoCode: this.cart.promocodeDetails.displayCode,
          system: this.system,
          email: user.contactEmail,
          type,
          username: user.userName,
          subscriptionTypeId: this.cart.subscriptionTypeId,
          currentPassType: user.subscriptionTypeId,
          currentStatus: user.subscriptionActivationStatus,
        },
      });
      this.cart.promocodeDetails = response;
    },
    async fetchProrateDiscount() {
      if (
        !this.passInCart?.prorateOnChange ||
        !this.currentSubscriptionActive ||
        this.currentPassType?.family !== PASS_FAMILY.member ||
        this.getUpcomingPasses({ dedupe: false })?.length
      ) {
        return undefined;
      }

      const response = await this.fetchBts("user/prorated-renewal/");
      if (!response.proratedDiscount) {
        return undefined;
      }
      this.cart.proratedDiscount = response.proratedDiscount;

      return response.proratedDiscount;
    },
    async purchaseProratedPass() {
      if (
        !this.passInCart?.prorateOnChange ||
        !this.currentSubscriptionActive ||
        this.currentPassType?.family !== PASS_FAMILY.member ||
        this.getUpcomingPasses({ dedupe: false })?.length
      ) {
        throw new Error("User is not eligible for prorated pass");
      }
      const request = {
        pass_type: this.cart.subscriptionTypeId,
      };
      try {
        const response = await this.fetchBts("user/prorated-renewal/", {
          method: "POST",
          body: request,
        });
        this.clearCartOnPurchase();
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async validateDiscount() {
      try {
        const response = await this.fetchWithoutAuth(`${cloudflareAPI}/reducedFare`, {
          method: "POST",
          body: { cart: this.cart, system: this.system },
        });
        this.cart.discountVerified = response.discountVerified;
        // Set default selected subscription to one of the same duration as current subscription
        if (this.user?.subscriptionTypeId) {
          const reducedFarePasses = this.getPassTypes({
            visibleOnly: true,
            autoRenewingOnly: true,
            reducedFareOnly: true,
          });
          this.cart.subscriptionTypeId = reducedFarePasses.find(
            (pass) => pass.duration === this.getPassType(this.user.subscriptionTypeId).duration,
          )?.id;
        }
        return response;
      } catch (e) {
        console.error(e);
        throw new Error(
          "An error occured validating your eligibility. Please try again or contact customer service for assistance.",
        );
      }
    },
    async lookUpExistingUser(by = "phone") {
      let body = {
        contactPhone: this.cart.newUser.contactPhone,
        system: this.system,
      };

      if (by === "email") {
        body = {
          contactEmail: this.cart.newUser.contactEmail,
          system: this.system,
        };
      }

      try {
        const response = await this.fetchWithoutAuth(`${cloudflareAPI}/lookup`, {
          method: "POST",
          body,
        });
        return response.username || true;
      } catch (e) {
        switch (+e.status) {
        case 403:
          // multiple accounts
          return true;
        case 404:
          // No user
          return false;
        default:
          return false;
        }
      }
    },
    async requestPhoneVerificationCode({ channel }) {
      return await this.fetchWithoutAuth(`${cloudflareAPI}/phoneVerification`, {
        method: "POST",
        body: {
          contactPhone: this.cart.newUser.contactPhone,
          channel,
          system: this.system,
        },
      });
    },
    async checkPhoneVerificationCode(code) {
      if (!code) {
        throw new Error("Please enter the confirmation code you received via SMS");
      }
      try {
        const response = await this.fetchWithoutAuth(`${cloudflareAPI}/phoneVerification`, {
          method: "POST",
          body: {
            contactPhone: this.cart.newUser.contactPhone,
            code,
            system: this.system,
          },
        });
        if (+response.status === 200) {
          this.cart.phoneConfirmed = true;
        }
        return response;
      } catch (e) {
        console.error(e);
        throw new Error("Code could not be verified");
      }
    },
    async register() {
      const { subscriptionTypeId } = this.cart;
      const { isAutoRenewing } = this.getPassType(subscriptionTypeId);
      this.cart.newUser.isAutoRenewing = isAutoRenewing;

      // if the user didn't add a zipcode, try to read the zipcode from their credit card
      if (!this.cart.newUser.addressZipCode && this.paymentProfile.stripeToken.card) {
        if (
          !!this.paymentProfile.stripeToken.card.address_zip &&
          !this.paymentProfile.stripeToken.card.address_zip.match(/[A-Za-z]/)
        ) {
          this.cart.newUser.addressZipCode = this.paymentProfile.stripeToken.card.address_zip;
          // if the card is international and not matching bcycle's formatting requirements, set a dummy value
        } else if (this.paymentProfile.stripeToken.card.country !== "US") {
          this.cart.newUser.addressZipCode = "11111";
        }
      }
      // check for payment button international users and make sure bcycle will accept their zipcode
      if (
        this.cart.newUser.countryId + "" !== "236" &&
        (this.cart.newUser.addressZipCode.match(/[A-Za-z]/) || !this.cart.newUser.addressZipCode)
      ) {
        // TODO: look into converting payment button country to countryId for BCycle
        this.cart.newUser.addressZipCode = "11111";
      }

      let response;
      try {
        response = await this.fetchWithoutAuth(`${cloudflareAPI}/register`, {
          method: "POST",
          body: {
            cart: this.cart,
            system: this.system,
            tokenId: this.paymentProfile.stripeToken.id,
          },
        });
      } catch(e) {
        throw new Error(e?.data?.error || "Could not process sign up request. Please try again or contact customer service.");
      }
      
      try {
        this.clearCartOnPurchase();
        this.referralCandy = response?.referralCandyInfo;
        this.justPurchasedCost = response?.justPurchasedCost;

        metaPixelTrackPurchase({
          justPurchasedCart: this.justPurchasedCart,
          justPurchasedCost: this.justPurchasedCost,
        });

        sendAppMessage({
          action: "signup",
          parameters: {
            username: this.justPurchasedCart.newUser.contactEmail,
            password: this.justPurchasedCart.newUser.password,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    async toggleAutoRenew() {
      const shouldAutoRenew = !this.user.isAutoRenewing;
      const body = {
        isAutoRenewing: shouldAutoRenew.toString(),
      };
      try {
        await this.fetchBcycle("1/user", {
          method: "PATCH",
          body,
        });
        await this.fetchUser();
        if (this.user.isAutoRenewing !== shouldAutoRenew) {
          // Bcycle call will only error if the request is bad
          // It won't error if the operation is unsuccessful
          // So we need to manually check
          throw new Error();
        }
      } catch (error) {
        throw new Error(
          `Could not ${
            !shouldAutoRenew ? "cancel" : "reactivate"
          } pass. Please try again or contact customer service.`,
        );
      }
    },
    async renewOrChangePass() {
      if (this.mustUpdateCard) {
        throw new Error("Please update payment information");
      }

      if (this.cart.proratedDiscount) {
        return this.purchaseProratedPass();
      }

      // Migrated from renew_user in class-bcycle-api.php
      const { subscriptionTypeId, promocodeDetails } = this.cart;
      const { isAutoRenewing } = this.getPassType(subscriptionTypeId);
      const body = {
        type: "Renew",
        subscriptionTypeId,
        isAutoRenewing,
        fullfillCard: false,
        purchaseSource: "PartnerRenewal",
        paymentProfile: {
          paymentProcessorType: "Stripe",
          paymentCollectionType: "Website",
        },
      };
      if (promocodeDetails.code) {
        body.promotionCode = promocodeDetails.code;
      }
      try {
        const response = await this.fetchBcycle("3/registration", {
          method: "POST",
          body,
        });

        this.justPurchasedCost = response.cost;

        this.clearCartOnPurchase();

        metaPixelTrackPurchase({
          justPurchasedCart: this.justPurchasedCart,
          justPurchasedCost: this.justPurchasedCost,
        });

        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async setDefaultCart() {
      if (!this.user) {
        await this.fetchUser();
      }
      if (!this.cart) {
        this.cart = JSON.parse(JSON.stringify(stateDefaults.cart));
      }
      const { subscriptionTypeId } = this.user;
      if (!this.cart.subscriptionTypeId) {
        this.cart.subscriptionTypeId = subscriptionTypeId;
      }
      if (!this.cart.discountVerificationForm) {
        this.cart.discountVerificationForm = stateDefaults.cart.discountVerificationForm;
      }
    },
    async fetchTripsForRating() {
      const now = new Date();
      const dateQuery = {
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      };
      const trips = await this.fetchTrips(dateQuery);
      // if there are not a lot of trips, load the previous month
      if (trips.length < MIN_TRIPS_FOR_RATING) {
        now.setMonth(now.getMonth() - 1);
        dateQuery.month = now.getMonth() + 1;
        dateQuery.year = now.getFullYear();
        await this.fetchTrips(dateQuery);
      }
    },
    async submitTripRating(request) {
      sendAppMessage({
        action: "rating",
        parameters: {
          rating: request.rating,
          reportedIssue: request.issues.length > 0,
          leftComment: !!request.comment,
        },
      });
      return this.fetchBts("user/trip-rating/", {
        method: "POST",
        body: request,
      });
    },
    handlePassInCartChange() {
      this.cart.promocodeDetails = JSON.parse(JSON.stringify(stateDefaults.cart.promocodeDetails));
      this.cart.proratedDiscount = stateDefaults.cart.proratedDiscount;
    },
    clearCartOnPurchase() {
      this.justPurchasedCart = this.cart;
      this.cart = JSON.parse(JSON.stringify(stateDefaults.cart));
    },
    async fetchUserKeyCard() {
      try {
        if (!this.user) {
          await this.fetchUser();
        }
        const response = await this.fetchBcycle(`1/user/usercard?userId=${this.user.userId}`);
        this.userKeyCard = response;
        return response;
      } catch (error) {
        if (+error.status === 404) {
          return;
        }
        throw new Error(
          "Error fetching information. Please try again or contact customer service.",
        );
      }
    },
    async orderKeyCard() {
      try {
        if (!this.userKeyCard) {
          // They might not have one, but we should check
          await this.fetchUserKeyCard();
        }
        this.fetchBts("user/key-card/", {
          method: "POST",
          body: {
            user: this.user,
            // Backend will order new if they already have one, and order replacement if they don't
            userKeyCard: this.userKeyCard,
          },
        });
      } catch (error) {
        console.error(error);
        throw new Error("Could not order key card");
      }
    },
  },
};
