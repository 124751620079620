/* eslint-disable no-unused-vars */
import { useStorage } from "@vueuse/core";
import { stateDefaults } from "../../src/stores/stateDefaults";
import { systemData, PASS_DURATION, PASS_FAMILY } from "../../src/systemData";
import {
  paymentProfile,
  expiredPaymentProfile,
  creditCallPaymentProfile,
  payNearMePaymentProfile,
} from "./paymentProfile";
import { statements } from "./statement";
import { trips } from "./trip";
import { user } from "./user";
import { userSummary } from "./userSummary";
import { getSubscription } from "./subscription";

const rewardPoints = {
  points_this_month: 10,
  points_last_month: 11,
  points_all_time: 250,
  unfulfilled_points_all_time: 150,
  rewards_this_month: null,
  rewards_last_month: null,
  rewards_all_time: null,
};

const defaultState = {
  user,
  userSummary,
  paymentProfile,
  statements,
  // trips,
};

const passStates = [
  {
    ...defaultState,
    id: "active",
    name: "Active",
    families: [PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Active",
    },
  },
  {
    ...defaultState,
    id: "active-expiring",
    name: "Active (no auto-renew)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Active",
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "active-delinquent",
    name: "Active (delinquent)",
    families: [PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Active",
      isDelinquent: true,
    },
  },
  {
    ...defaultState,
    id: "active-delinquent-expiring",
    name: "Active (delinquent; no auto-renew)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Active",
      isDelinquent: true,
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "not-activated",
    name: "Not Activated",
    families: [PASS_FAMILY.casual],
    user: {
      ...user,
      subscriptionActivationStatus: "NotActivated",
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "not-activated",
    systems: ["las"],
    name: "Not Activated",
    families: [PASS_FAMILY.member],
    user: {
      ...user,
      subscriptionActivationStatus: "NotActivated",
      isAutoRenewing: true,
    },
  },
  {
    ...defaultState,
    id: "not-activated-delinquent",
    name: "Not Activated (delinquent)",
    families: [PASS_FAMILY.casual],
    user: {
      ...user,
      subscriptionActivationStatus: "NotActivated",
      isDelinquent: true,
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "expired",
    name: "Expired",
    families: [PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Expired",
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "expired-delinquent",
    name: "Expired (delinquent)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Expired",
      isDelinquent: true,
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "expired-not-auto-renewing",
    name: "Expired (no auto-renew)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Expired",
      isAutoRenewing: false,
    },
  },
  {
    ...defaultState,
    id: "expired-delinquent-expired-card",
    name: "Expired (delinquent; expired card)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Expired",
      isDelinquent: true,
      isAutoRenewing: false,
    },
    paymentProfile: expiredPaymentProfile,
  },
  {
    ...defaultState,
    id: "expired-creditcall",
    name: "Expired (CreditCall)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Expired",
      isAutoRenewing: false,
    },
    paymentProfile: creditCallPaymentProfile,
  },
  {
    ...defaultState,
    id: "expired-paynearme",
    name: "Expired (PayNearMe)",
    families: [PASS_FAMILY.casual, PASS_FAMILY.member, PASS_FAMILY.restricted],
    user: {
      ...user,
      subscriptionActivationStatus: "Expired",
      isAutoRenewing: false,
    },
    paymentProfile: payNearMePaymentProfile,
  },
  {
    ...defaultState,
    id: "upcoming",
    name: "Upcoming (subscription)",
    families: [PASS_FAMILY.member],
    user: {
      ...user,
      isAutoRenewing: true,
    },
    userSummary: {
      ...userSummary,
      subscriptions: [
        getSubscription({ subscriptionActivationStatus: "NotActivated" }),
        getSubscription({ passType: { duration: PASS_DURATION.month }, subscriptionActivationStatus: "NotActivated" }),
      ],
    },
  },
  {
    ...defaultState,
    id: "upcoming-casual",
    name: "Upcoming (casual)",
    families: [PASS_FAMILY.casual],
    user: {
      ...user,
      isAutoRenewing: false,
    },
    userSummary: {
      ...userSummary,
      subscriptions: [
        getSubscription({ subscriptionActivationStatus: "NotActivated" }),
        getSubscription({ passType: { duration: PASS_DURATION.month }, subscriptionActivationStatus: "NotActivated" }),
      ],
    },
  },
  {
    ...defaultState,
    id: "upcoming-multiple",
    name: "Upcoming (single ride, multiple passes)",
    families: [PASS_FAMILY.casual],
    systems: ["lax"],
    user: {
      ...user,
      subscriptionActivationStatus: "NotActivated",
      isAutoRenewing: false,
    },
    userSummary: {
      ...userSummary,
      subscriptions: [
        getSubscription({ subscriptionActivationStatus: "NotActivated" }),
      ],
    },
  },
];

function showPassState(passType, system, passState) {
  let show = passState.families.includes(passType.family);
  if (passState.systems?.length) {
    show = show && passState.systems.includes(system);
  }
  return show;
}

export function getPassStatesForPassType(passType, system = "phl") {
  if (!passType) {
    return [{
      id: "no-pass",
      name: "No pass",
      accessToken: "xyz",
      families: [PASS_FAMILY.none],
      user: {
        ...user,
        subscriptionTypeId: null,
        subscriptionActivationStatus: null,
        optInAutoRenewLastUpdateDate: null,
      },
      userSummary: {
        ...userSummary,
        membershipExpiration: null,
        subscriptions: [],
      },
    },
    {
      id: "no-account",
      name: "No account",
      accessToken: null,
      families: [PASS_FAMILY.none],
      user: {
      },
      userSummary: {
      },
    },
    ];
  }
  return JSON.parse(JSON.stringify(passStates))
    .filter(passState => showPassState(passType, system, passState))
    .map(passState => {
      // Deep copy so we don't overwrite reference values
      // https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy
      const _passState = JSON.parse(JSON.stringify({
        ...stateDefaults,
        rewardPoints,
        ...passState,
        accessToken: "xyz",
      }));
      _passState.user.subscriptionTypeId = passType.id;
      const { subscriptionActivationStatus } = _passState.user;
      _passState.userSummary.subscriptions = [getSubscription({
        passType,
        subscriptionActivationStatus,
      })];
      if (passState.id === "upcoming" || passState.id === "upcoming-casual") {
        _passState.userSummary.subscriptions.push(
          getSubscription({
            // Grab a pass that isn't the current pass
            passType: {
              duration: passType.duration === PASS_DURATION.year ? PASS_DURATION.month : PASS_DURATION.year,
            },
            subscriptionActivationStatus: "NotActivated",
            system,
          }),
        );
      }
      if (passState.id === "upcoming-multiple") {
        _passState.userSummary.subscriptions = _passState.userSummary.subscriptions.concat([
          getSubscription({
            passType: { duration: PASS_DURATION.single },
            subscriptionActivationStatus: "NotActivated",
            system,
          }),
          getSubscription({
            passType: { duration: PASS_DURATION.single },
            subscriptionActivationStatus: "NotActivated",
            system,
          }),
        ]);
      }
      return _passState;
    });
}

export default function () {
  const system = useStorage("system", "phl");
  const defaultPass = system.value ? getPassStatesForPassType(systemData[system.value].passTypes[0], system.value)[0] : {};
  return {
    system,
    isEmbedded: useStorage("isEmbedded", false),
    keyCard: false,
    ...defaultPass,
  };
}