<template>
  <div
    v-show="showTranslate"
    class="translate"
  >
    <div id="google_translate" />
    <ul class="notranslate translate-links">
      <template v-for="(name, shortcutLang) in shortcutLangs">
        <li v-if="shortcutLang != lang">
          <a
            :href="`?lang=${shortcutLang}`"
            @click.prevent="lang = shortcutLang"
          >{{ name }}</a>
        </li>
      </template>
      <li>
        <div class="translate-globe">
          <label for="select-language">
            <span class="sr-only">Translate</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M12 0a12 12 0 100 24 12 12 0 000-24zm1 16v-3h3c0 1.1-.2 2.2-.5 3.3L13 16zm2 2.2a12 12 0 01-2 3.5V18l2 .1zM13 11V8l2.5-.2c.3 1 .4 2 .5 3.2h-3zm0-5V2.3a12 12 0 012 3.5L13 6zm2.7-3.3A10 10 0 0119 5l-2 .6c-.4-1-.8-2-1.3-2.8zM8.5 16.3C8.2 15.3 8 14 8 13h3v3c-.9 0-1.7.2-2.5.3zM11 18v3.6a12 12 0 01-2-3.5l2-.1zM8 11c0-1.1.2-2.2.5-3.2L11 8v3H8zm1-5.2a12 12 0 012-3.5V6l-2-.2zm-2-.3l-2-.6c1-1 2-1.7 3.3-2.2-.5.9-1 1.8-1.3 2.8zm-.5 1.9C6.2 8.5 6 9.7 6 11H2a10 10 0 011.7-4.5c.8.3 1.8.7 2.8.9zm0 9.3c-1 .2-2 .5-2.8.9A10 10 0 012 13H6c0 1.3.2 2.5.5 3.7zm.5 1.9l1.3 2.7A10 10 0 015 19.2c.7-.3 1.3-.5 2-.6zm10 0c.7.1 1.3.3 2 .6-1 .9-2 1.6-3.3 2 .5-.8 1-1.7 1.3-2.6zm.5-2c.3-1.1.5-2.3.5-3.6h4a10 10 0 01-1.7 4.6l-2.8-1zM18 11c0-1.3-.2-2.5-.5-3.6 1-.2 2-.6 2.8-1A10 10 0 0122 11H18z"
              />
            </svg>
          </label>
          <select
            id="select-language"
            v-model="lang"
          >
            <option
              disabled
              selected
            >
              Select Language
            </option>
            <option
              v-for="(name, lang) in langs"
              :key="lang"
              :value="lang"
            >
              {{ name }}
            </option>
          </select>
        </div>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";

const langParam = new URLSearchParams(window.location.search).get("lang");

// see if google translate has a cookie saved with an existing translation (googtrans cookie is set in the format `/en/es` for english to spanish)
const googleTranslateCookie = document.cookie.split(";").find((cookie) => cookie.includes("googtrans"));
const googleTranslateLang = googleTranslateCookie?.split("/")[ 2 ];
const lang = ref(googleTranslateLang || "en");

const showTranslate = ref(googleTranslateLang || langParam ? true : false);

watch(lang, () => {
  const translateElement = document.querySelector("#google_translate .goog-te-combo");
  if (!translateElement) {
    return;
  }
  translateElement.value = lang.value;
  translateElement.dispatchEvent(new Event("change"));
});

window.GoogleLanguageTranslatorInit = () => {
  new google.translate.TranslateElement({
    pageLanguage: "en",
    includedLanguages: Object.keys(langs).join(","),
    layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
    autoDisplay: false,
  }, "google_translate");
  let checkInterval = setInterval(() => {
    const translateElement = document.querySelector("#google_translate .goog-te-combo");
    if (!langParam) {
      clearInterval(checkInterval);
      return;
    }
    if (!translateElement) {
      // wait for element to be created
      return;
    }
    setTimeout(() => {
      clearInterval(checkInterval);
      lang.value = langParam;
    }, 1000);
  }, 1000);
};

// add script to load google translate
onMounted(() => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "//translate.google.com/translate_a/element.js?cb=GoogleLanguageTranslatorInit";
  document.body.appendChild(script);
});

const shortcutLangs = {
  "en": "English",
  "es": "Español",
  "zh-CN": "中文",
};

const langs = {
  "en": "English",
  "af": "Afrikaans",
  "sq": "Albanian",
  "am": "Amharic",
  "ar": "Arabic",
  "hy": "Armenian",
  "az": "Azerbaijani",
  "eu": "Basque",
  "be": "Belarusian",
  "bn": "Bengali",
  "bs": "Bosnian",
  "bg": "Bulgarian",
  "ca": "Catalan",
  "ceb": "Cebuano",
  "ny": "Chichewa",
  "zh-CN": "Chinese (Simplified)",
  "zh-TW": "Chinese (Traditional)",
  "co": "Corsican",
  "hr": "Croatian",
  "cs": "Czech",
  "da": "Danish",
  "nl": "Dutch",
  "eo": "Esperanto",
  "et": "Estonian",
  "tl": "Filipino",
  "fi": "Finnish",
  "fr": "French",
  "fy": "Frisian",
  "gl": "Galician",
  "ka": "Georgian",
  "de": "German",
  "el": "Greek",
  "gu": "Gujarati",
  "ht": "Haitian Creole",
  "ha": "Hausa",
  "haw": "Hawaiian",
  "iw": "Hebrew",
  "hi": "Hindi",
  "hmn": "Hmong",
  "hu": "Hungarian",
  "is": "Icelandic",
  "ig": "Igbo",
  "id": "Indonesian",
  "ga": "Irish",
  "it": "Italian",
  "ja": "Japanese",
  "jw": "Javanese",
  "kn": "Kannada",
  "kk": "Kazakh",
  "km": "Khmer",
  "ko": "Korean",
  "ku": "Kurdish (Kurmanji)",
  "ky": "Kyrgyz",
  "lo": "Lao",
  "la": "Latin",
  "lv": "Latvian",
  "lt": "Lithuanian",
  "lb": "Luxembourgish",
  "mk": "Macedonian",
  "mg": "Malagasy",
  "ms": "Malay",
  "ml": "Malayalam",
  "mt": "Maltese",
  "mi": "Maori",
  "mr": "Marathi",
  "mn": "Mongolian",
  "my": "Myanmar (Burmese)",
  "ne": "Nepali",
  "no": "Norwegian",
  "ps": "Pashto",
  "fa": "Persian",
  "pl": "Polish",
  "pt": "Portuguese",
  "pa": "Punjabi",
  "ro": "Romanian",
  "ru": "Russian",
  "sm": "Samoan",
  "gd": "Scots Gaelic",
  "sr": "Serbian",
  "st": "Sesotho",
  "sn": "Shona",
  "sd": "Sindhi",
  "si": "Sinhala",
  "sk": "Slovak",
  "sl": "Slovenian",
  "so": "Somali",
  "es": "Spanish",
  "su": "Sundanese",
  "sw": "Swahili",
  "sv": "Swedish",
  "tg": "Tajik",
  "ta": "Tamil",
  "te": "Telugu",
  "th": "Thai",
  "tr": "Turkish",
  "uk": "Ukrainian",
  "ur": "Urdu",
  "uz": "Uzbek",
  "vi": "Vietnamese",
  "cy": "Welsh",
  "xh": "Xhosa",
  "yi": "Yiddish",
  "yo": "Yoruba",
  "zu": "Zulu",
};
</script>
<style lang="scss">
body {
  top: 0 !important;
}

.skiptranslate {
  display: none !important;
}

#google_translate {
  display: none;
}

.translate-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5em 1em;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.translate-globe {
  position: relative;
  height: 100%;
  width: 1rem;
}

.translate-globe select {
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0;
  border: none;
  font-size: 1rem;
  appearance: none;
  outline: none;
  box-shadow: none;
  background: none;
  text-indent: 2em;
}

.translate-globe label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.translate-globe svg {
  width: 100%;
  height: auto;
  fill: #0082cb;
}

.translate-globe:hover svg,
.translate-globe:focus svg {
  fill: #16216a;
}

html[lang^=es] .gtl-language-es,
html[lang^=en] .gtl-language-en,
html[lang=zh-CN] .gtl-language-zh-CN {
  display: none;
}

.show-if-es,
.show-if-en,
.show-if-zh-ch {
  display: none !important;
}

html[lang^=es] .show-if-es,
html[lang^=en] .show-if-en,
html[lang=zh-CN] .show-if-zh-ch {
  display: block !important;
}
</style>