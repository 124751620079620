<template>
  <ReducedFareValidation>
    <template
      #summary
    >
      <p>Enter your 16 digit Golden State Advantage Card number to verify that you are eligible for a discounted pass.</p>
    </template>
    <template #eligibility-fields>
      <MaskedTextInput
        v-model="account.cart.discountVerificationForm.californiaEBT"
        :oruga-field-props="useErrorProps.fieldProps"
        :oruga-input-props="orugaInputProps"
        mask="XXXX XXXX XXXX XXXX"
        @valid="v => valid = v"
      />
    </template>
    <template
      #details
    >
      <p>Your Golden State Advantage card cannot be used to pay for your Metro Bike Share pass. Entering your number will only verify that you are eligible for this discount. Metro Bike Share passes must be purchased using a debit or credit card.</p>
      <p>Up to four family members of the same household are eligible to sign up with the same Golden State Advantage card.</p>
      <p>We respect your privacy! Your Golden State Advantage card number will not be shared or used for any reason other than to verify your eligibility. For any questions, please call or text our help desk at <a href="tel:+18448572453">(844) 857-2453</a> or email <a href="mailto:support@bikeshare.metro.net">support@bikeshare.metro.net</a>.</p>
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import { useAccount } from "../../../../stores/account";
import MaskedTextInput from "../../../MaskedTextInput.vue";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();

const valid = ref(false);
const useErrorProps = useFieldErrorProvider(
  () => account.cart.discountVerificationForm.californiaEBT,
  "laxEBT",
  { valid },
);

const orugaInputProps = {
  ...useErrorProps.inputProps,
  name: "ebt-card",
  required: true,
  autofocus: true,
};

onUnmounted(() => {
  useErrorProps.clearErrors();
});
</script>
