export const NOT_ELIGIBLE_TYPE = "none";

export const stateDefaults = {
  isEmbedded: false,
  user: null,
  userSummary: null,
  unpaidFees: null,
  paymentProfile: null,
  rewardPoints: null,
  rewardOptions: null,
  userKeyCard: null,
  trips: {},
  statements: {},
  referralCandy: null,
  frequentedStations: [],
  justPurchasedCart: {}, // set after pass purchase
  justPurchasedCost: null, // set after registration
  cart: {
    subscriptionTypeId: null,
    newUser: {
      acceptedEula: false,
      fullfillCard: false,
    }, // same fields as existing user
    demographics: {}, // fields to be sent to demographics table
    phoneConfirmed: false,
    discountPortalID: null,
    discountType: NOT_ELIGIBLE_TYPE, // Verification discount type
    discountVerificationForm: {
      accessCard: null,
      nevadaEBT: null,
      rtcRFID: {
        cardNumber: null,
        expiration: null,
        cardType: null,
      },
      metroTAP: null,
      californiaEBT: null,
      disability: {
        selfCert: false,
        agreeToProve: false,
      },
      studentEmail: null,
      senior: {
        birthdate: null,
        agreeToProve: false,
      },
    },
    discountVerified: null,
    promocodeDetails: {
      code: null,
      displayCode: null,
      discountedPassCost: null,
      newUsersOnly: null,
    },
    proratedDiscount: undefined,
  },
};