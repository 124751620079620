import { createI18n } from "vue-i18n";
import {
  en_US_las,
  en_US_lax,
  en_US_phl,
} from "./locales";

export function getLocaleFromSystem(system = "phl", lang = "en_US") {
  return `${lang}_${system}`;
}

const defaultI18nOptions = {
  locale: getLocaleFromSystem(localStorage.getItem("system")),
  fallbackLocale: getLocaleFromSystem(),
  legacy: false,
  messages: {
    en_US_las,
    en_US_lax,
    en_US_phl,
  },
};

export function setupI18n(options = defaultI18nOptions) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

// TODO: use this in mock account settings
export function setI18nLanguage(i18n, locale) {
  i18n.global.locale.value = locale;
  const htmlLang = locale.slice(0, 2);
  document.querySelector("html").setAttribute("lang", htmlLang);
}

