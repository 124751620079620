export const frequentedStations = [
  {
    kioskId: 3054,
    name: "Rodin Museum",
    addressStreet: "2170 Hamilton St.",
    addressCity: "Philadelphia",
    addressState: "PA",
    addressZipCode: "19146",
    locationLatitude: 39.96250,
    locationLongitude: -75.17420,
    virtualKioskLocationId: null,
    bikesAvailable: 11,
    trikesAvailable: 0,
    docksAvailable: 24,
    totalDocks: 35,
    openTime: "04:00:00",
    closeTime: "03:55:00",
    timeZone: "Eastern Standard Time",
    isVisible: false,
    isDeleted: false,
    isVirtual: false,
    publicText: "",
    notes: null,
    ordinalRank: 1,
    geofence: null,
    isEventBased: false,
    batteryPackId: null,
    maxBikeLimit: 0,
    stopUuid: "00000000-0000-0000-0000-000000000000",
    kioskEvents: null,
    connectedDocks: null,
    eventStart: null,
    eventEnd: null,
    kioskPublicStatus: "Active",
    programId: 0,
    kioskTypeId: 0,
    kioskType: null,
    internetConnectionType: null,
    isCritical: false,
    kioskVersion: null,
    maintenanceBeginTime: null,
    maintenanceEndTime: null,
    lastKnownIP: null,
    optionalKioskId: null,
    powerSourceType: null,
    serialNumber: null,
    dockConfigurationId: null,
    webServiceUser: null,
    webServicePasswordDigest: null,
    webServicePasswordSalt: null,
    newWebServicePassword: null,
    radioHeadMacAddress: null,
    kioskAd: null,
    mapModule: null,
    terminalId: null,
    transactionKey: null,
    advertisingDetails: null,
    regionId: null,
    hasNewDockCount: false,
    hasNewOpenHours: false,
    hasNewMaintenanceHours: false,
    lastWebServiceUserName: null,
    lastWebServicePasswordDigest: null,
    lastWebServicePasswordSalt: null,
    hasNewStatus: false,
    phoneNumber: null,
    ndnNumber: null,
    imeiNumberSimIdField: null,
    pcSerialNumber: null,
    clientVersion: null,
    pairingCode: null,
    deviceId: null,
    hasGeofence: false,
    classicBikesAvailable: 0,
    smartBikesAvailable: 0,
    electricBikesAvailable: 0,
    isArchived: false,
    primaryBeaconMacAddress: null,
    secondaryBeaconMacAddress: null,
    isPaired: false,
    isCcrDisabled: false,
    pendingLogUpload: false,
    screenSizeId: "Unknown",
  },
  {
    kioskId: 3120,
    name: "31st & Girard",
    addressStreet: "1210 N. 31st Street",
    addressCity: "Philadelphia",
    addressState: "PA",
    addressZipCode: "19121",
    locationLatitude: 39.97522,
    locationLongitude: -75.18669,
    virtualKioskLocationId: null,
    bikesAvailable: 6,
    trikesAvailable: 0,
    docksAvailable: 12,
    totalDocks: 19,
    openTime: "04:00:00",
    closeTime: "03:55:00",
    timeZone: "Eastern Standard Time",
    isVisible: false,
    isDeleted: false,
    isVirtual: false,
    publicText: "",
    notes: null,
    ordinalRank: 2,
    geofence: null,
    isEventBased: false,
    batteryPackId: null,
    maxBikeLimit: 0,
    stopUuid: "00000000-0000-0000-0000-000000000000",
    kioskEvents: null,
    connectedDocks: null,
    eventStart: null,
    eventEnd: null,
    kioskPublicStatus: "Active",
    programId: 0,
    kioskTypeId: 0,
    kioskType: null,
    internetConnectionType: null,
    isCritical: false,
    kioskVersion: null,
    maintenanceBeginTime: null,
    maintenanceEndTime: null,
    lastKnownIP: null,
    optionalKioskId: null,
    powerSourceType: null,
    serialNumber: null,
    dockConfigurationId: null,
    webServiceUser: null,
    webServicePasswordDigest: null,
    webServicePasswordSalt: null,
    newWebServicePassword: null,
    radioHeadMacAddress: null,
    kioskAd: null,
    mapModule: null,
    terminalId: null,
    transactionKey: null,
    advertisingDetails: null,
    regionId: null,
    hasNewDockCount: false,
    hasNewOpenHours: false,
    hasNewMaintenanceHours: false,
    lastWebServiceUserName: null,
    lastWebServicePasswordDigest: null,
    lastWebServicePasswordSalt: null,
    hasNewStatus: false,
    phoneNumber: null,
    ndnNumber: null,
    imeiNumberSimIdField: null,
    pcSerialNumber: null,
    clientVersion: null,
    pairingCode: null,
    deviceId: null,
    hasGeofence: false,
    classicBikesAvailable: 0,
    smartBikesAvailable: 0,
    electricBikesAvailable: 0,
    isArchived: false,
    primaryBeaconMacAddress: null,
    secondaryBeaconMacAddress: null,
    isPaired: false,
    isCcrDisabled: false,
    pendingLogUpload: false,
    screenSizeId: "Unknown",
  },
  {
    kioskId: 3058,
    name: "20th & Fairmount",
    addressStreet: "1940 Fairmount Ave.",
    addressCity: "Philadelphia",
    addressState: "PA",
    addressZipCode: "19130",
    locationLatitude: 39.96716,
    locationLongitude: -75.17001,
    virtualKioskLocationId: null,
    bikesAvailable: 3,
    trikesAvailable: 0,
    docksAvailable: 16,
    totalDocks: 19,
    openTime: "04:00:00",
    closeTime: "03:55:00",
    timeZone: "Eastern Standard Time",
    isVisible: false,
    isDeleted: false,
    isVirtual: false,
    publicText: "",
    notes: null,
    ordinalRank: 3,
    geofence: null,
    isEventBased: false,
    batteryPackId: null,
    maxBikeLimit: 0,
    stopUuid: "00000000-0000-0000-0000-000000000000",
    kioskEvents: null,
    connectedDocks: null,
    eventStart: null,
    eventEnd: null,
    kioskPublicStatus: "Active",
    programId: 0,
    kioskTypeId: 0,
    kioskType: null,
    internetConnectionType: null,
    isCritical: false,
    kioskVersion: null,
    maintenanceBeginTime: null,
    maintenanceEndTime: null,
    lastKnownIP: null,
    optionalKioskId: null,
    powerSourceType: null,
    serialNumber: null,
    dockConfigurationId: null,
    webServiceUser: null,
    webServicePasswordDigest: null,
    webServicePasswordSalt: null,
    newWebServicePassword: null,
    radioHeadMacAddress: null,
    kioskAd: null,
    mapModule: null,
    terminalId: null,
    transactionKey: null,
    advertisingDetails: null,
    regionId: null,
    hasNewDockCount: false,
    hasNewOpenHours: false,
    hasNewMaintenanceHours: false,
    lastWebServiceUserName: null,
    lastWebServicePasswordDigest: null,
    lastWebServicePasswordSalt: null,
    hasNewStatus: false,
    phoneNumber: null,
    ndnNumber: null,
    imeiNumberSimIdField: null,
    pcSerialNumber: null,
    clientVersion: null,
    pairingCode: null,
    deviceId: null,
    hasGeofence: false,
    classicBikesAvailable: 0,
    smartBikesAvailable: 0,
    electricBikesAvailable: 0,
    isArchived: false,
    primaryBeaconMacAddress: null,
    secondaryBeaconMacAddress: null,
    isPaired: false,
    isCcrDisabled: false,
    pendingLogUpload: false,
    screenSizeId: "Unknown",
  },
];