import { systemData, PASS_DURATION } from "../../src/systemData";

const defaultPassDetails = {
  passType: { duration: PASS_DURATION.year },
  system: "phl",
};
export const getSubscription = function ({
  passType = { duration: PASS_DURATION.year },
  system = "phl",
  subscriptionActivationStatus,
} = defaultPassDetails) {
  let _passType = passType;
  if (!_passType.id) {
    _passType = systemData[system].passTypes.find(p => p.duration === passType.duration);
  }
  const {
    id: subscriptionTypeId,
    name: subscriptionTypeName,
  } = _passType;

  let startDate, endDate;
  const oldestDate = new Date("2022-01-02T00:00:00.000-08:00");
  const oldDate = new Date("2022-12-12T00:00:00.000-08:00");
  const newDate = new Date("2026-01-02T00:00:00.000-08:00");
  if (newDate < new Date()) {
    console.error("Fake data is out of date. Please set newDate, newerDate, and newestDate to dates in the future");
  }
  const newerDate = new Date("2027-12-12T00:00:00.000-08:00");
  const newestDate = new Date("2028-01-02T00:00:00.000-08:00");

  // If subscription activation status is not provided, only return partial pass details so that they may be overwritten with a spread operator
  if (subscriptionActivationStatus === "Active") {
    startDate = oldestDate.toISOString();
    endDate = newestDate.toISOString();
    if (passType.duration === PASS_DURATION.month) {
      endDate = newDate.toISOString();
    }
  } else if (subscriptionActivationStatus === "Expired") {
    startDate = oldestDate.toISOString();
    endDate = oldDate.toISOString();
  } else if (subscriptionActivationStatus === "NotActivated") {
    startDate = newerDate.toISOString();
    endDate = newestDate.toISOString();
  }

  return {
    // Fields not yet used in this app are commented out
    // aboutToExpireNotificationSent: false,
    // autoActivationDayDurationAtPurchase: 0,
    endDate,
    // isMemberSubscription: true,
    // lastSettledDate: "0001-01-01T00:00:00",
    // paymentAuthorizationId: 0,
    // renterId: 659344,
    startDate,
    subscriptionId: 2400133,
    subscriptionTypeId,
    subscriptionTypeName,
    // billingFrequencyId: "Daily",
  };
};