<template>
  <ReducedFareValidation>
    <template #summary>
      <p>
        Students 16 or older with a valid student email qualify for a reduced fare pass. Enter your student email address to verify your eligibility for a student pass.
      </p>
    </template>
    <template #eligibility-fields>
      <o-field
        v-bind="useErrorProps.fieldProps"
      >
        <o-input
          v-bind="useErrorProps.inputProps"
          v-model="account.cart.discountVerificationForm.studentEmail"
          type="email"
          maxlength="40" 
          required
          autofocus
          @blur="useErrorProps.inputProps.onBlur"
        />
      </o-field>
      <button
        v-if="suggestedEmailAddress"
        id="email-suggestion"
        type="button"
        @mouseup.prevent="acceptEmailSuggestion"
        @keyup.prevent="acceptEmailSuggestion"
      >
        <p
          class="o-field__message o-field__message-info"
        >
          {{ `Did you mean ${suggestedEmailAddress}?` }}
        </p>
      </button>
    </template>
    <template
      #details
    >
      If you do not have a student email address, please visit <ExternalLink
        text=" TAPToGo.net"
        href="https://www.taptogo.net/"
      /> to apply for a Reduced Fare TAP Card. Then, you can use your TAP Card number to validate your eligibility for a reduced fare bike share pass.
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { computed, onUnmounted } from "vue";
import emailSpellChecker from "@zootools/email-spell-checker";
import { useAccount } from "../../../../stores/account";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import ExternalLink from "../../../ExternalLink.vue";
import { SUGGESTED_DOMAINS } from "../../../../utils";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();
const useErrorProps = useFieldErrorProvider(
  () => account.cart.discountVerificationForm.studentEmail,
  "student",
);

const suggestedEmailAddress = computed(() => {
  if (!account?.cart?.discountVerificationForm?.studentEmail) { return null; }
  const suggestion = emailSpellChecker.run({
    domains: [...emailSpellChecker.POPULAR_DOMAINS, ...SUGGESTED_DOMAINS], email: account.cart.discountVerificationForm.studentEmail,
  })?.full;
  return suggestion;
});

function acceptEmailSuggestion(e) {
  // For some bizarre reason, tab was triggering a click event, so this and the mouseup + keyup listeners are the workaround
  if (e.code && e.code !== "Enter" && e.code !== "Space") { return; }
  account.cart.discountVerificationForm.studentEmail = suggestedEmailAddress.value;
}

onUnmounted(() => {
  useErrorProps.clearErrors();
});
</script>

<style lang="scss">
#student-email {
  @at-root .o-field--addons {
    display: block;
  }

  @at-root .o-ctrl-input {
    flex: 1;
  }
}

#app #email-suggestion {
  all: unset;
  box-sizing: inherit;
  display: block;
  font-size: var(--oruga-field-message-font-size, 0.75rem);
  margin-top: var(--oruga-field-message-margin-top, 0.25rem);
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    font-weight: bold;
  }

  p {
    margin-block-start: 0;
    margin-top: 0;
    padding: 0;
  }
}
</style>