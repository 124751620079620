import { emailValid, isDateNumberOfYearsAgo, studentEmailValid } from "../../utils";


function returnMessageIfEmptyString(value, message) {
  if (!value) return message;
  let stringValue = value + "";
  if (!stringValue?.length) { return message; }
  let newVal = stringValue.replace(/\s/g, "");
  if (!newVal?.length) { return message; }
  return null;
}

/**
 * key: any valid object key
 * id: a unique valid HTML id to match the input to the label, and ALSO to serve as an anchor link for the error message at the top of the form
 * userFieldKey: used only for address validation, to denote which field on the user object is associated
 * getLabel: a func to get the label for the field, can be passed an options object
 * validate: a func to validate the user input value
 */
export const fieldIds = {
  street: {
    id: "address-street",
    userFieldKey: "addressStreet",
    getLabel: () => "Address Number and Street",
    validate: (value) => returnMessageIfEmptyString(value, "Address number and street cannot be blank"),
  },
  city: {
    id: "address-city",
    userFieldKey: "addressCity",
    getLabel: () => "City",
    validate: (value) => returnMessageIfEmptyString(value, "City cannot be blank"),
  },
  state: {
    id: "address-state",
    userFieldKey: "addressState",
    getLabel: ({ addressIsDomestic }) => addressIsDomestic ? "State" : "State/Province",
    validate: (value) => returnMessageIfEmptyString(value, "State cannot be blank"),
  },
  zip: {
    id: "address-zip",
    userFieldKey: "addressZipCode",
    getLabel: ({ addressIsDomestic }) => addressIsDomestic ? "ZIP Code" : "ZIP/Postal Code",
    validate: (value) => returnMessageIfEmptyString(value, "ZIP code cannot be blank"),
  },
  country: {
    id: "address-country",
    userFieldKey: "countryId",
    getLabel: () => "Country",
    validate: (value) => returnMessageIfEmptyString(value, "Country cannot be blank"),
  },
  region: {
    id: "user-region",
    // TODO: use i18n once key fob PR is merged
    getLabel: () => "Where do you plan to use Metro Bike Share the most?",
    validate: (value) => returnMessageIfEmptyString(value, "Region cannot be blank"),
  },
  email: {
    id: "email",
    getLabel: () => "Email Address",
    validate: (value) => emailValid(value) ? undefined : "Email format is invalid",
  },
  firstName: {
    id: "first-name",
    getLabel: () => "First Name",
    validate: (value) => returnMessageIfEmptyString(value, "First name cannot be blank"),
  },
  lastName: {
    id: "last-name",
    getLabel: () => "Last Name",
    validate: (value) => returnMessageIfEmptyString(value, "Last name cannot be blank"),
  },
  password: {
    id: "new-password",
    getLabel: () => "New Password",
    validate: (value) => value.length >= 8 ? undefined : "Password must be at least 8 characters long",
  },
  passwordReentered: {
    id: "new-password-reentered",
    getLabel: () => "Reenter New Password",
    validate: (value, { password }) => password.value.length >= 8 && value === password.value ? undefined : "Passwords must match",
  },
  phone: {
    id: "phone",
    getLabel: () => "Phone Number",
    validate: (value, { valid }) => valid.value ? undefined : "Phone number format is invalid",
  },
  confirmation: {
    id: "confirmation-code",
    getLabel: () => "Confirmation Code",
    validate: (value) => returnMessageIfEmptyString(value, "Please enter the confirmation code you received via SMS"),
  },
  laxEBT: {
    id: "ebt-card",
    getLabel: () => "Golden State Advantage Card Number",
    validate: (value, { valid }) => valid.value ? undefined : "Card number is invalid",
  },
  disabilitySelfCert: {
    id: "disability-self-cert",
    getLabel: () => "Per the ADA definition provided above, I am disabled.",
    validate: (value) => value ? undefined : "Must indicate disability status",
  },
  disabilityProof: {
    id: "disability-proof",
    getLabel: () => "I understand that in the future, I may be asked to provide proof of my disability. I agree that if I do not provide proof of disability, my benefits may be terminated from the Reduced Fare program.",
    validate: (value) => value ? undefined : "Must indicate willingness to prove disability status",
  },
  metroTAP: {
    id: "tap-card",
    getLabel: () => "TAP Card Number",
    validate: (value, { valid }) => valid.value ? undefined : "TAP card number is invalid",
  },
  seniorAge: {
    id: "birthdate",
    getLabel: () => "Birthdate (MM/DD/YYYY)",
    validate: (value) => {
      return value ? (isDateNumberOfYearsAgo(value) && !isDateNumberOfYearsAgo(value, 120) ? undefined : "Ineligible birthdate") : "Valid birthdate required";
    },
  },
  seniorProof: {
    id: "senior-proof",
    getLabel: () => "By checking this box, I confirm that I am 62 years old or older. I understand that in the future, I may be asked to provide proof of my age. I agree that if I do not provide proof of my age, my benefits may be terminated from the Reduced Fare program.",
    validate: (value) => value ? undefined : "Must indicate willingness to prove age",
  },
  student: {
    id: "student-email",
    getLabel: () => "Student Email",
    validate: (value) => studentEmailValid(value) ? undefined : "Valid student email address is required",
  },
  access: {
    id: "access-card",
    getLabel: () => "ACCESS Card Number",
    validate: (value, { valid }) => valid.value ? undefined : "ACCESS card number is invalid",
  },
  lasEBT: {
    id: "ebt-card",
    getLabel: () => "Nevada EBT Card Number",
    validate: (value, { valid }) => valid.value ? undefined : "EBT card number is invalid",
  },
  rtcRFID: {
    id: "rfid-exp-card",
    getLabel: () => "Expiration Date",
    validate: (value) => value ? (new Date(value) > new Date() ? undefined : "Invalid expiration date") : "Expiration date required",
  },
  tosUnattended: {
    id: "tos-unattended",
    getLabel: ({ t }) => t("userAgreement.unattended"),
    validate: (value) => value ? undefined : "You must agree to never leave bikes unattended",
  },
  tosReplacement: {
    id: "tos-replacement",
    getLabel: ({ t }) => t("userAgreement.replacement"),
    validate: (value) => value ? undefined : "You must accept the fee for lost or stolen bicycles",
  },
  tosEula: {
    id: "tos-eula",
    getLabel: ({ t }) => t("userAgreement.eula"),
    validate: (value) => value ? undefined : "You must accept the terms of the User Agreement",
  },
  payment: {
    id: "user-card",
    getLabel: () => "", // dummy func, this is not used
    validate: (value, { error }) => error.value,
  },
  keyCard: {
    id: "user-key-card",
    getLabel: ({ t }) => `Send me a ${t("keyCard")}`,
    validate: () => undefined,
  },
};
