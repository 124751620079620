<template>
  <ReducedFareValidation>
    <template
      #summary
    >
      <p>A discounted bike share pass is available to people 62 years old and older.</p>
    </template>
    <template #eligibility-fields>
      <fieldset class="fieldset">
        <legend class="legend legend--smaller sr-only">
          Self-certify age
        </legend>
        <o-field
          v-bind="birthdateErrorProps.fieldProps"
        >
          <o-input
            v-bind="birthdateErrorProps.inputProps"
            v-model="account.cart.discountVerificationForm.senior.birthdate"
            type="date"
            placeholder="MM/DD/YYYY"
            autofocus
            editable
            required
            :min-date="minDate"
            :max-date="maxDate"
            :trap-focus="false"
            @blur="birthdateErrorProps.inputProps.onBlur"
          />
        </o-field>
        <o-checkbox
          :id="proofErrorProps.inputProps.id"
          v-model="account.cart.discountVerificationForm.senior.agreeToProve"
          type="checkbox"
          :variant="proofErrorProps.fieldProps.variant"
          required
        >
          {{ proofErrorProps.fieldProps.label }}
        </o-checkbox>
      </fieldset>
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { onUnmounted } from "vue";
import { useAccount } from "../../../../stores/account";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();

const birthdateErrorProps = useFieldErrorProvider(() =>
  account.cart.discountVerificationForm.senior.birthdate, "seniorAge");
const proofErrorProps = useFieldErrorProvider(() =>
  account.cart.discountVerificationForm.senior.agreeToProve, "seniorProof");

const today = new Date();
const minDate = new Date(`${today.getFullYear() - 120}-01-01`);
const maxDate = today;

onUnmounted(() => {
  birthdateErrorProps.clearErrors();
  proofErrorProps.clearErrors();
});
</script>
