<!-- Used only in composition in each discount pass validation -->
<template>
  <div class="reduced-fare-validation">
    <div class="reduced-fare-validation__summary">
      <slot name="summary" />
    </div>
    <slot name="eligibility-fields" />
    <div class="reduced-fare-validation__info">
      <slot name="details" />
    </div>
  </div>
</template>

<script setup>
import { useAccount } from "../../../stores/account";
import { stateDefaults } from "../../../stores/stateDefaults";

const account = useAccount();
if (!account.cart.discountVerificationForm) {
  account.cart.discountVerificationForm = { ...stateDefaults.cart.discountVerificationForm };
}
</script>
