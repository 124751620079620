<template>
  <ReducedFareValidation>
    <template #summary>
      <p>Enter your PA Access Card number to verify that you are eligible for a discounted pass.</p>
    </template>
    <template #eligibility-fields>
      <MaskedTextInput
        :v-model="account.cart.discountVerificationForm.accessCard"
        :oruga-field-props="useErrorProps.fieldProps"
        :oruga-input-props="orugaInputProps"
        mask="XXXXXX XXXXXXXXXX XX X"
        @update:model-value="newValue => account.cart.discountVerificationForm.accessCard = newValue"
        @valid="v => valid = v"
      />
    </template>
    <template #details>
      <p>
        Your PA ACCESS Card cannot be used to pay for your Indego Pass. Entering your number will only verify that you
        are eligible for this pass type. Payment must be made using a credit card.
      </p>
      <p>Up to four family members of the same household are eligible to sign up with the same ACCESS card.</p>
      <p>
        We respect your privacy! Your PA ACCESS Card number will not be shared or used for any reason other than to check
        your eligibility. For any questions, please call or text our help desk at
        <a href="tel:+18444463346">(844) 446-3346</a> or email
        <a href="mailto:support@rideindego.com">support@rideindego.com</a>.
      </p>
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import { useAccount } from "../../../../stores/account";
import MaskedTextInput from "../../../MaskedTextInput.vue";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();

const valid = ref(false);
const useErrorProps = useFieldErrorProvider(
  () => account.cart.discountVerificationForm.accessCard,
  "access",
  { valid },
);

const orugaInputProps = {
  ...useErrorProps.inputProps,
  name: "access-card",
  required: true,
  autofocus: true,
};

onUnmounted(() => {
  useErrorProps.clearErrors();
});
</script>
