<template>
  <o-notification
    type="danger"
    variant="danger"
    aria-close-label="Close notification"
  >
    <div
      ref="boxEl"
      class="error-box"
      tabindex="0"
    >
      <h1>
        {{ header }}
      </h1>
      <slot name="message">
        <p>
          Something went wrong. Try refreshing the page or restarting the app.
        </p>
      </slot>
      <p v-if="includeCSInfo && account.currentSystem">
        If the problem persists, please contact customer service at <a
          :href="`mailto:${account.currentSystem.supportEmail}`"
        >{{ account.currentSystem.supportEmail }}</a> or <a :href="`tel:${account.currentSystem.supportPhone}`">{{ formatPhoneNumberForDisplay(account.currentSystem.supportPhone) }}</a>.
      </p>
      <p v-else-if="includeCSInfo">
        If the problem persists, please contact customer service.
      </p>
    </div>
  </o-notification>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAccount } from "../../stores/account";
import { formatPhoneNumberForDisplay } from "../../utils";

// Reference
// https://www.levelaccess.com/blog/how-to-provide-accessible-form-error-identification/

const account = useAccount();

defineProps({
  header: { type: String, default: "Error" },
  includeCSInfo: { type: Boolean, default: true },
});

const boxEl = ref();
onMounted(() => {
  boxEl.value.focus();
});

</script>


<style lang="scss">
#app .error-box {
  color: white;

  a:not(.o-btn) {
    color: inherit;
  }

  > * {
    margin: 0.5rem 0;
  }

  h1 {
    font-size: 1.5em;
    color: inherit; // override wp style
  }

  ul {
    padding-inline-start: 1.5em;
  }

  .o-btn--inverted,
  .o-btn--outlined {
    &.o-btn--danger {
      border: 2px solid black;
      color: black;
    }
  }
}
</style>