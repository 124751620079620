import { currentPhlPasses, notForPublicPurchasePhlPasses, deprecatedPhlPasses } from "./phlPasses";
import { currentLaxPasses, notForPublicPurchaseLaxPasses, deprecatedLaxPasses } from "./laxPasses";
import { currentLasPasses, notForPublicPurchaseLasPasses, deprecatedLasPasses } from "./lasPasses";
import { phlDiscounts } from "./phlDiscounts";

function addDiscountsToPassData(passes, discounts) {
  if (import.meta.env.VITE_TEST) {
    console.log("Skipping pass discount addition");
    return passes;
  }
  const _discounts = discounts.filter((d) => d.shouldApply());
  return passes.map((p) => ({
    ...p,
    discounts: _discounts.filter((d) => d.subscriptionTypeId === p.id),
  }));
}

export const systemData = {
  phl: {
    name: "Indego Bike Share",
    bcycleApi: "https://portal-phl.bcycle.com",
    btsApi: "https://api-phl.bicycletransit.com",
    launchDate: new Date("2015-04-23"),
    salesTax: 0.08,
    salesTaxStartDate: new Date("2021-04-06 00:00:00T06:00:00"),
    programId: 3,
    state: "PA",
    stripePublicKey: "pk_live_J8PMOZP0pkpOhqQEIGhUkRn7",
    wordPressURL: "https://www.rideindego.com",
    surveyLink: "https://www.rideindego.com/profile/#/survey",
    supportPhone: "+18444463346",
    supportEmail: "support@rideindego.com",
    supportOperatingHours: "8am to 11pm (phone support 8am to 8pm Mon-Fri)",
    passTypes: addDiscountsToPassData(
      [...currentPhlPasses, ...notForPublicPurchasePhlPasses, ...deprecatedPhlPasses],
      phlDiscounts,
    ),
    delayPrepaidCardValidation: false,
  },
  lax: {
    name: "Metro Bike Share",
    bcycleApi: "https://portal-lax.bcycle.com",
    btsApi: "https://api-lax.bicycletransit.com",
    launchDate: new Date("2016-07-07"),
    programId: 88,
    state: "CA",
    stripePublicKey: "pk_live_hxRrMmINaLH4cZ47Jv9S0gRy",
    wordPressURL: "https://bikeshare.metro.net",
    surveyLink: "https://bikeshare.metro.net/legacy-profile/#/survey",
    supportPhone: "+18448572453",
    supportEmail: "support@bikeshare.metro.net",
    supportOperatingHours: "8am to 8pm",
    passTypes: [...currentLaxPasses, ...notForPublicPurchaseLaxPasses, ...deprecatedLaxPasses],
    regions: [
      {
        id: "downtown_la",
        registration_title: "Central LA",
        title: "Central LA",
        latitude: 34.048,
        longitude: -118.25,
        zoom: 14,
      },
      {
        id: "venice",
        registration_title: "Westside",
        title: "Westside",
        latitude: 33.99958,
        longitude: -118.44136,
        zoom: 14,
      },
      {
        id: "north_hollywood",
        registration_title: "North Hollywood",
        title: "North Hollywood",
        latitude: 33.99958,
        longitude: -118.44136,
        zoom: 14,
      },
    ],
    delayPrepaidCardValidation: true,
  },
  las: {
    name: "RTC Bike Share",
    bcycleApi: "https://portal-den.bcycle.com",
    btsApi: "https://api-las.bicycletransit.com",
    launchDate: new Date("2016-10-27"),
    programId: 91,
    state: "NV",
    stripePublicKey: "pk_live_6u6rcocPzWS1pVaJIb5QQw0F",
    wordPressURL: "https://bikeshare.rtcsnv.com",
    surveyLink: "https://bikeshare.rtcsnv.com/account/#/survey",
    supportPhone: "+18446417823",
    supportEmail: "support@bikeshare.rtcsnv.com",
    supportOperatingHours: "8am to 8pm (phone support Mon-Fri only)",
    passTypes: [...currentLasPasses, ...notForPublicPurchaseLasPasses, ...deprecatedLasPasses],
    delayPrepaidCardValidation: false,
  },
};
