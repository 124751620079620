
<template>
  <div
    :class="[
      'site',
      `site--${account.system}`,
      { 
        'site--iframe': isIFrame,
        'site--embedded': account.isEmbedded,
        'site--wp-embedded': wpEmbedded
      },
    ]"
  >
    <MockAccountSettings
      v-if="showMockAccountSettings"
    />
    <GoogleTranslate v-if="!wpEmbedded" />
    <header class="site-header desktop">
      <SkipNav focus-el-i-d="account-app-main" />
      <div class="site-header-inner desktop">
        <router-link
          :to="{ name: 'home' }"
        >
          <h1>My Account</h1>
        </router-link>
        <nav aria-label="Account nav bar">
          <ul v-if="account.accessToken">
            <li
              v-for="navItem in navItems"
              :key="navItem.text"
              :class="navItem.containerClass"
            >
              <router-link
                v-bind="navItem.props"
              >
                {{ navItem.text }}
              </router-link>
            </li>
            <li>
              <a
                id="account-app-logout-desktop"
                href="javascript:void(0)"
                @click="logOut"
              >Log Out</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <header class="site-header mobile tablet">
      <SkipNav focus-el-i-d="account-app-main" />
      <nav aria-label="Account nav bar">
        <o-dropdown
          v-if="account.accessToken"
          aria-role="list"
        >
          <template #trigger>
            <o-button
              type="button"
            >
              My Account
              <InlineIcon
                image-path="fa-bars.svg"
                alt-text=""
              />
            </o-button>
          </template>

          <o-dropdown-item
            value="home"
            tabindex="-1"
            aria-role="listitem"
          >
            <router-link
              :to="{ name: 'home' }"
            >
              My Account Home
            </router-link>
          </o-dropdown-item>

          <o-dropdown-item
            v-for="navItem in navItems"
            :key="navItem.text"
            :class="navItem.containerClass"
            :value="navItem.text"
            tabindex="-1"
            aria-role="listitem"
          >
            <router-link v-bind="navItem.props">{{ navItem.text }}</router-link>
          </o-dropdown-item>

          <o-dropdown-item
            value="logout"
            tabindex="-1"
            aria-role="listitem"
          >
            <a
              id="account-app-logout-mobile"
              href="javascript:void(0)"
              @click="logOut"
            >Log Out</a>
          </o-dropdown-item>
        </o-dropdown>
      </nav>
    </header>
    <component
      :is="wpEmbedded ? 'div' : 'main'"
      id="account-app-main"
      :key="account.id"
      class="main"
    >
      <ErrorBoundary>
        <router-view :key="route.fullPath" />
      </ErrorBoundary>
    </component>
  </div>
</template>
<script setup>
import { computed, watch } from "vue";
import { useAccount } from "./stores/account";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import MockAccountSettings from "../test/MockAccountSettings.vue";
import ErrorBoundary from "./components/alerts/ErrorBoundary.vue";
import InlineIcon from "./components/InlineIcon.vue";
import SkipNav from "./components/SkipNav.vue";
import GoogleTranslate from "./components/GoogleTranslate.vue";

const account = useAccount();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const isIFrame = window.location !== window.parent.location;

const showMockAccountSettings = import.meta.env.VITE_MOCK_USE_ACCOUNT;
const wpEmbedded = import.meta.env.VITE_WP_EMBED;

function logOut() {
  account.logout();
}

const accessToken = computed(() => account.accessToken);
watch(accessToken, (newVal, oldVal) => {
  if (!newVal && !!oldVal) {
    router.push({ name: "login", query: { redirect: route.fullPath } });
  }
});

const navItems = [
  {
    text: "Rate",
    containerClass: "hide-wp-embedded",
    props: {
      to: { name: "rate-trip-select" },
    },
  },
  {
    text: "Trip History",
    props: {
      to: { name: "trips" },
    },
  },
  {
    text: "Payment and Billing",
    props: {
      to: { name: "billing-info" },
    },
  },
  {
    text: "TAP Card",
    containerClass: "only-wp-embedded lax-only",
    props: {
      to: { name: "tapCard" },
    },
  },
  {
    text: "Edit Profile",
    props: {
      to: { name: "profile" },
    },
  },
  {
    text: "Survey",
    containerClass: "only-wp-embedded",
    props: {
      to: { name: "survey" },
    },
  },
  {
    text: "Refer a Friend",
    containerClass: "only-wp-embedded",
    props: {
      to: { name: "refer" },
    },
  },
  {
    text: t("keyCard"),
    containerClass: "not-lax capitalize",
    props: {
      to: { name: "key-card" },
    },
  },
];
</script>

<style lang="scss">
@import "../assets/media-queries.scss";

#app .site.site--wp-embedded header.site-header.desktop .site-header-inner {
  padding: 16px 0;
}

#app .site header.site-header {
  padding: 0;
  margin: 0;

  &.desktop {
    display: none;
    border-top: 1px solid var(--header-background-color);
    border-bottom: 1px solid var(--header-background-color);
    margin-bottom: 2em;

    @include media("screen", ">tablet") {
      display: block;
    }

    .site-header-inner {
      margin: 0 auto;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    h1 {
      margin: 0;
      font-size: 1.25em;
      font-weight: normal;
    }

    nav ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 0 1.5em;

      li {
        padding: 0;
        margin: 0;
      }
    }
  }

  &.mobile, &.tablet {
    display: flex;
    @include media("screen", ">tablet") {
      display: none;
    }

    justify-content: stretch;
    margin-bottom: 1rem;

    nav {
      width: 100%;
    }
    .o-drop {
      width: 100%;

      &__item {
        min-width: 250px;
      }
    }
    .o-btn {
      width: 100%;
      border-radius: unset;
      &__label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  a {
    font-weight: normal;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  .router-link-active, .router-link-active h1 {
    font-weight: bold;
  }
}

#app .site--iframe, #app .site--embedded:not(.site--wp-embedded) {
  #mock-account {
    margin-bottom: 1rem;
  }

  header.site-header {
    display: none !important;
  }
}
</style>