<!-- https://webaim.org/techniques/skipnav/ -->

<template>
  <a
    class="skipNav"
    :href="`#${focusElID}`"
    @click="onClick"
  >Skip to content</a>
</template>

<script setup>
const props = defineProps({
  // Should be main element
  focusElID: {
    type: String,
    required: true,
  },
});

function onClick(e) {
  e.preventDefault();
  document.getElementById(props.focusElID).tabIndex = 0;
  document.getElementById(props.focusElID).focus();
  document.getElementById(props.focusElID).tabIndex = -1;
}
</script>

<style lang="scss">
.skipNav {
  left: 0;
  padding: 8px 16px;
  position: absolute;
  top: -96px;
  z-index: 100;
}
.skipNav:focus {
  left: 0;
  top: 0;
  background-color: white;
}
</style>