<template>
  <o-field
    v-bind="orugaFieldProps"
    :label-for="orugaInputProps.id"
    :message="message || orugaFieldProps.message"
    :variant="variant"
  >
    <o-input
      v-bind="orugaInputProps"
      v-model="formattedString"
      type="text"
      @blur="onBlur"
    />
  </o-field>
</template>
<script setup>
import { computed, ref, watch, watchEffect } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  mask: {
    type: String,
    required: true,
  },
  orugaFieldProps: {
    type: Object,
    required: true,
  },
  orugaInputProps: {
    type: Object,
    required: true,
  },
  numeric: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "valid"]);

const unformattedString = ref(props.modelValue);
watch(unformattedString, (newValue) => {
  emit("update:modelValue", newValue);
});

const formattedString = computed({
  get() {
    const insertAfterNumberOfDigits = props.mask.split("").reduce(
      (acc, v, i) => (v === " " && acc.push(i), acc),
      []);

    if(unformattedString.value?.length < insertAfterNumberOfDigits[0]) {
      return unformattedString.value;
    }

    const returnStringArray = unformattedString.value?.split("") || [];
    insertAfterNumberOfDigits.forEach((digit) => {
      returnStringArray.splice(digit, 0, " ");
    });

    return returnStringArray.join("").trimEnd();
  },
  set(newValue) { unformattedString.value = newValue.replaceAll(" ", "");},
});

const blurred = ref(false);
function onBlur() {
  blurred.value = true;
  if (props.orugaInputProps.onBlur) {
    props.orugaInputProps.onBlur();
  }
}

const validLength = computed(() => formattedString.value?.length === props.mask?.length);
const validCharacters = props.numeric ? computed(() => unformattedString.value.match(/^\d+$/)) : { value: true };

const message = computed(() => {
  if (!blurred.value) {
    return null;
  } else if (!validLength.value) {
    return `Must match format ${props.mask}`;
  } else if (!validCharacters.value) {
    return "Can only contain digits 0-9";
  }
  return null;
});

watchEffect(() => {
  emit("valid", validLength.value && validCharacters.value);
});

const variant = computed(() => {
  if (message.value) {
    return "danger";
  }
  return props.orugaFieldProps.variant;
});
</script>