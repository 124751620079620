<template>
  <ReducedFareValidation>
    <template
      #summary
    >
      <p>Enter your 16 digit Nevada EBT Card number to verify that you are eligible for a discounted pass.</p>
    </template>
    <template #eligibility-fields>
      <MaskedTextInput
        :v-model="account.cart.discountVerificationForm.nevadaEBT"
        :oruga-field-props="useErrorProps.fieldProps"
        :oruga-input-props="orugaInputProps"
        mask="XXXX XXXX XXXX XXXX"
        @valid="v => valid = v"
      />
    </template>
    <template
      #details
    >
      <p>
        Your Nevada EBT Card cannot be used to pay for your RTC Bike Share Pass.
        Entering your number will only verify that you are eligible for the
        Reduced Fare discount. Payment must be made using a credit card.
      </p>
      <p>
        Up to four family members of the same household are eligible to sign up
        with the same EBT card.
      </p>
      <p>
        We respect your privacy! Your Nevada EBT Card number will not be shared
        or used for any reason other than to check your eligibility. For any
        questions, please call or text our help desk at
        <a href="tel:+18444463346">(844) 446-3346</a> or email
        <a
          href="mailto:support@bikeshare.rtcsnv.com"
        >support@bikeshare.rtcsnv.com</a>.
      </p>
      <div
        id="snhd-logo"
      >
        <img
          :src="getSNHDLogoUrl()"
          width="120"
          height="70"
          alt=""
        >
        <p>
          This discount is made possible by the Southern Nevada
          Health District.
        </p>
      </div>
    </template>
  </ReducedFareValidation>
</template>

<script setup>
import { onUnmounted, ref } from "vue";
import { useAccount } from "../../../../stores/account";
import MaskedTextInput from "../../../MaskedTextInput.vue";
import ReducedFareValidation from "../ReducedFareValidationTemplate.vue";
import { useFieldErrorProvider } from "../../../FieldErrorProvider/useFieldErrorProvider";

const account = useAccount();

const valid = ref(false);
const useErrorProps = useFieldErrorProvider(
  () => account.cart.discountVerificationForm.nevadaEBT,
  "lasEBT",
  { valid },
);

const orugaInputProps = {
  ...useErrorProps.inputProps,
  name: "ebt-card",
  required: true,
  autofocus: true,
};

onUnmounted(() => {
  useErrorProps.clearErrors();
});

const getSNHDLogoUrl = () => {
  return new URL("../../../../../assets/images/snhd-logo.png", import.meta.url).href;
};
</script>

<style lang="scss">
#snhd-logo {
  text-align: center;
  margin-top: 2em;

  p {
    text-align: center;
    font-size: 0.75em;
    font-style: italic;
  }
}
</style>