<template>
  <div
    v-if="imageIsSvg"
    :role="altText ? 'img' : 'presentation'"
    :aria-label="altText"
    class="inline-icon"
    :class="classes"
    v-html="svg"
  />
  <div
    v-else-if="imagePath"
    class="inline-icon"
    :class="classes"
  >
    <img
      :alt="altText"
      :src="getImageUrl()"
    >
  </div>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  imagePath: { type: String, required: true },
  altText: { type: String, default: "" },
  classes: { type: String, default: "" },
});

const getImageUrl = () => {
  // For non-svg images
  if (!props.imagePath) return;
  return new URL(`../../assets/images/${props.imagePath}`, import.meta.url).href;
};

// Use svgs inline so we can set the color dynamically with css and use currentColor as a fill
const imageIsSvg = props.imagePath?.endsWith("svg");
const svg = ref("");
if (imageIsSvg) {
  // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
  const svgPath = props.imagePath.replace(".svg", "");
  import(`../../assets/images/${svgPath}.svg?raw`).then((module) => {
    svg.value = module.default;
  });
}
</script>

<style>
.inline-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>