<template>
<ErrorBox v-if="hasError">
  <template v-if="errorMessage" #message>
    <p>
      {{ errorMessage }}
    </p>
  </template>
</ErrorBox>
<slot v-else />
</template>
<script setup>
import { ref, onErrorCaptured, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useAccount } from "../../stores/account";
import ErrorBox from "./ErrorBox.vue";
import { notifyHoneybadger, setHoneybadgerContext } from "../../utils";

const account = useAccount();
const hasError = ref(false);
const errorMessage = ref(null);
const route = useRoute();

watchEffect(() => {
  setHoneybadgerContext({ route: route.fullPath, ...account.$state });
});

const consoleLogErrrors = import.meta.env.DEV || import.meta.env.VITE_MOCK_USE_ACCOUNT || window.location.origin.endsWith("wpengine.com");


onErrorCaptured((error) => {
  // Errors in vue components, but not async events
  hasError.value = true;
  if (consoleLogErrrors) {
    console.error("Error captured: ", error.stack);
  }
  notifyHoneybadger(error);
  return false;
});

window.addEventListener("unhandledrejection", function (event) {
  event.preventDefault();
  event.stopPropagation();

  // Handle unhandled promise rejections
  hasError.value = true;
  errorMessage.value = event.reason;
  if (consoleLogErrrors) {
    console.error("Unhandled rejection: ", event, event.reason);
  }
  notifyHoneybadger(event);
  return false;
});

watch(route, () => {
  hasError.value = false;
  errorMessage.value = null;
});

</script>
