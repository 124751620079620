import { getSubscription } from "./subscription";

// TODO: MAKE THIS A GET FUNCTION like subscriptions
export const userSummary = {
  milesRidden: 130.79976,
  moneySaved: 132.84876,
  // TODO: dynamic based on current system
  membershipType: "Indego30",
  // Subscriptions should get overridden in getPassStatesForPassType
  subscriptions: getSubscription(),
  memberSince: "2020-09-06T15:02:18.46Z",
  // TODO: dynamically set in the future
  membershipExpiration: "2024-05-04T15:02:14.5Z",
  caloriesBurned: 5231.9904,
  carbonOffset: 124.259772,
};