<template>
  <span>
    <a
      :href="href"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ text }}
      <img
        :src="getImageUrl()"
        alt=" (goes to external website)"
      >
    </a>
  </span>
</template>

<script setup>
defineProps({
  text: {type: String, required: true},
  href: {type: String, required: true},
});

const getImageUrl = () => {
  return new URL("../../assets/images/external-link.svg", import.meta.url).href;
};

</script>
