export default {
  keyCard: "key fob",
  userAgreement: {
    unattended: "I agree never to leave a bike unattended when not securely docked in a station.",
    replacement: "I understand that if the bike is lost or stolen, my account will be suspended and charged a bike replacement fee of up to $2,500.",
    eula: "I am 14 years old or older, and I have read and agree to the conditions set forth in the <a href=\"https://www.rideindego.com/user-agreement/\" target=\"_blank\">User Agreement</a>.",
  },
  demographicsSurvey: {
    preLinkText: "For a chance to win an annual pass, complete our ",
    linkText: "demographics survey.",
  },
  reducedFare: {
    sectionDescription: "Pennsylvania ACCESS cardholders qualify for discounted annual and monthly passes",
  },
};