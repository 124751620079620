import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { useAccount } from "./stores/account";
import { systemData } from "./systemData";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./pages/PageHome.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settle-fees",
    name: "settle-fees",
    component: () => import("./pages/PageSettleFees.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/update-payment",
    name: "update-payment",
    component: () => import("./pages/PageUpdatePayment.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/billing-info",
    name: "billing-info",
    component: () => import("./pages/PagePayments.vue"),
  },
  {
    path: "/statements",
    name: "statements",
    component: () => import("./pages/PagePayments.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/trips",
    name: "trips",
    component: () => import("./pages/PageTrips.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("./pages/PageSignUp/PageSignUp.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("./pages/PagePricing/PagePricing.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/renew",
    name: "renew",
    component: () => import("./pages/PageRenew/PageRenew.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/change",
    name: "change",
    component: () => import("./pages/PageChange/PageChange.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/rewards/redeem/:id",
    name: "rewardRedeem",
    component: () => import("./pages/PageRewardRedeem.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rewards/redeem",
    name: "rewardsList",
    component: () => import("./pages/PageRewardsRedeemList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/PageLogin.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("./pages/PagePasswordReset/PagePasswordReset.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/trips/rate/:id",
    name: "rate-trip",
    component: () => import("./pages/PageRateTrip.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/trips/rate",
    name: "rate-trip-select",
    component: () => import("./pages/PageRateTripSelect.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cancel",
    name: "cancel",
    component: () => import("./pages/PageCancelReactivate.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reactivate",
    name: "reactivate",
    component: () => import("./pages/PageCancelReactivate.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tapCard",
    name: "tapCard",
    component: () => import("./pages/PageTAPCard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/refer",
    name: "refer",
    component: () => import("./pages/PageRefer.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("./pages/PageProfile/PageProfile.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("./pages/PageSurvey.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/key-card",
    name: "key-card",
    component: () => import("./pages/PageUserKeyCard/PageUserKeyCard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("./pages/NotFound.vue"),
    meta: { requiresAuth: true },
  },
];

export const router = createRouter({
  history: import.meta.env.VITE_WP_EMBED ? createWebHashHistory() : createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  const account = useAccount();

  if (to.query.promo) {
    account.cart.promocodeDetails.code = to.query.promo;
  }

  if (to.query.access_token && to.query.system) {
    account.accessToken = to.query.access_token;
    to.query.access_token = undefined;
    account.system = to.query.system;
    to.query.system = undefined;
    gtag("set", "user_properties", {
      system: account.system,
    });
    account.isEmbedded = true;
    return to;
  }

  if (import.meta.env.VITE_MOCK_USE_ACCOUNT && !to.query.mock && from.query.mock === "true") {
    to.query.mock = "true";
    return to;
  }

  if (!account.system && !to.query.system && to.name !== "login") {
    // TODO: what should actually happen if there's no system?
    return {
      name: "login",
      query: {
        redirect: to.fullPath,
      },
    };
  }

  if (!account.accessToken && to.meta.requiresAuth) {
    if (to.query.system) {
      account.system = to.query.system;
      to.query.system = undefined;
      gtag("set", "user_properties", {
        system: account.system,
      });
      return to;
    }
    if (to.name !== "login") {
      return {
        name: "login",
        query: {
          redirect: to.fullPath,
        },
      };
    }
  }

  if (to.query.system) {
    if (Object.keys(systemData).findIndex((s) => s === to.query.system) === -1) {
      account.system = undefined;
      to.query.system = undefined;
      return to;
    }
    account.system = to.query.system;
    account.isEmbedded = true;
    to.query.system = undefined;
    if (typeof gtag !== "undefined") {
      gtag("set", "user_properties", {
        system: account.system,
      });
    }
    return to;
  }

  if (import.meta.env.VITE_MOCK_USE_ACCOUNT && to.query.isEmbedded === "true") {
    account.isEmbedded = true;
    to.query.isEmbedded = undefined;
    return to;
  } else if (import.meta.env.VITE_MOCK_USE_ACCOUNT && to.query.isEmbedded === "false") {
    account.isEmbedded = false;
    to.query.isEmbedded = undefined;
    return to;
  }
});

router.afterEach((to) => {
  const fullPath = (window.location.pathname + to.path).replace("//", "/");
  /*eslint-disable */
  if (typeof gtag !== 'undefined') {
    gtag('set', 'page', fullPath);
    gtag('send', 'pageview');
  }
});