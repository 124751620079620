export const userKeyCard = {
  userCardId: 12345,
  rfidTag: "123456A123BC",
  userCardStatusId: "Active",
  userId: 1234567,
  programId: 55,
  isInspectionCard: false,
  isTimeResetCard: false,
  visibleId: "00011100",
  magStripeId: "123456A123BC",
  barcode: "00011100",
  visibleCardNumber: null,
  cardClassGroup: null,
  riderClass: null,
  userCardClassId: "BCycleCard",
  hotlistAction: null,
  preloadedDate: null,
  fulfillmentStarted: null,
  fulfillmentCompleted: null,
};