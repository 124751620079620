import { storeOptions } from "../src/stores/storeOptions";
import { getSubscription } from "./data/subscription";
import { trips } from "./data/trip";
import { frequentedStations } from "./data/frequentedStations.js";
import { systemData } from "../src/systemData";
import getDefaultState from "./data/index.js";
import { PASS_FAMILY } from "../src/systemData";

export const mockUseAccountOptions = {
  state: getDefaultState,
  getters: {
    ...storeOptions.getters,
    currentSystem() {
      return { ...systemData[this.system], stripePublicKey: "pk_test_QbVCiR5pLGuRKuETofXWuAMA" };
    },
    latestTrips() {
      return Object.values(this.trips).flat().slice(0, 5);
    },
  },
  actions: {
    ...storeOptions.actions,
    async login() {
      this.$reset();
      const response = {
        access_token: "fake_access_token",
        expires_in: 3600,
        token_type: "Bearer",
        refresh_token: "xyz",
        scope: "billing:modify offline_access user:modify user:read",
      };
      this.accessToken = response.access_token;
      return response;
    },
    logout() {
      this.$reset();
      this.accessToken = null;
    },
    async fetchUnpaidFees() {
      return this.unpaidFees;
    },
    async fetchRewardPoints() {
      return this.rewardPoints;
    },
    async fetchRewardOptions() {
      return this.rewardOptions;
    },
    async redeemReward() {
      return new Promise(() => ({ status: 200 }), () => ({ status: 500 }));
    },
    async fetchPaymentProfile() {
      return this.paymentProfile;
    },
    async fetchUser() {
      return this.user;
    },
    async updateUser(newUserDetails) {
      this.user = { ...this.user, ...newUserDetails };
    },
    async updatePassword() {
      return this.user;
    },
    async resetPassword() {
      return { status: 200 };
    },
    async fetchUserSummary() {
      return this.userSummary;
    },
    async fetchTrips({ year, month }) {
      this.trips[`${year}-${month}`] = trips;
      return trips;
    },
    async fetchFrequentedStations() {
      this.frequentedStations = frequentedStations;
      return frequentedStations;
    },
    async fetchStatements() {
      return this.statements;
    },
    async updatePaymentProfile() {
      console.log("Updating fake payment profile");
      return {
        status: 200,
      };
    },
    async settleDelinquency() {
      this.user.isdDelinquent = false;
      return {
        status: 200,
      };
    },
    async validatePromoCode() {
      this.cart.promocodeDetails = {
        code: this.cart.promocodeDetails.displayCode,
        displayCode: this.cart.promocodeDetails.displayCode,
        discountedPassCost: 0,
        newUsersOnly: false,
      };
    },
    async fetchProrateDiscount() {
      if (!this.passInCart?.prorateOnChange || !this.currentSubscriptionActive || this.currentPassType?.family !== PASS_FAMILY.member || this.getUpcomingPasses({ dedupe: false })?.length) {
        return undefined;
      }

      const proratedDiscount = 12.23;

      this.cart.proratedDiscount = proratedDiscount;

      return proratedDiscount;
    },
    async purchaseProratedPass() {
      if (!this.passInCart?.prorateOnChange 
        || !this.currentSubscriptionActive 
        || this.currentPassType?.family !== PASS_FAMILY.member 
        || this.getUpcomingPasses({ dedupe: false })?.length) {
        throw new Error("User is not eligible for prorated pass");
      }
      this.userSummary.subscriptions.push(getSubscription({ 
        passType: this.getPassType(this.cart.subscriptionTypeId),
        subscriptionActivationStatus: "NotActivated", 
      }));
      this.clearCartOnPurchase();
    },
    async validateDiscount() {
      this.cart.discountVerified = "abcdefg";
    },
    async lookUpExistingUser(by = "phone") {
      if (by === "phone") {
        // for mock purposes, we'll return true if the phone number ends with 5555
        return this.cart.newUser.contactPhone?.endsWith("5555");
      }
      if (by === "email") {
        // for mock purposes, we'll return true if the email starts with test
        return this.cart.newUser.contactEmail?.startsWith("test");
      }
      return false;
    },
    async requestPhoneVerificationCode() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    },
    async checkPhoneVerificationCode(code) {
      if (!code) {
        throw new Error("Please enter the confirmation code you received via SMS");
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          this.cart.phoneConfirmed = true;
          resolve();
        }, 1000);
      });
    },
    async register() {
      console.log("cart: ", this.cart);
      this.justPurchasedCost = 10;
      this.clearCartOnPurchase();
    },
    async toggleAutoRenew() {
      this.user.isAutoRenewing = !this.user.isAutoRenewing;
    },
    async renewOrChangePass() {
      if (this.mustUpdateCard) {
        throw new Error("Please update payment information");
      }

      const passType = this.getPassType(this.cart.subscriptionTypeId);
      this.userSummary.subscriptions.push(getSubscription({ 
        passType,
        subscriptionActivationStatus: "NotActivated", 
      }));
      
      if (this.cart.proratedDiscount) {
        return this.purchaseProratedPass();
      }

      this.justPurchasedCost = passType.price;
      this.clearCartOnPurchase();
    },
    async setDefaultCart() {
      const { subscriptionTypeId } = this.user;
      if (!this.cart.subscriptionTypeId) {
        this.cart.subscriptionTypeId = subscriptionTypeId;
      }
    },
    async fetchTripsForRating() {
      const date = new Date();
      await this.fetchTrips({ year: date.getFullYear(), month: date.getMonth() + 1 });
    },
    async submitTripRating() {
      return new Promise(() => ({ status: 200 }), () => ({ status: 500 }));
    },
    async fetchUserKeyCard() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.userKeyCard);
        }, 1000);
      });
    },
    async orderKeyCard() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ status: 200 });
        }, 1000);
      });
    },
  },
};
