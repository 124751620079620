import { PASS_DURATION, PASS_FAMILY } from "./constants";

export const currentLasPasses = [
  {
    id: 374,
    name: "Dasher",
    displayPrice: "24 hours of access for $5",
    baseBenefits: "Unlimited 30-minute rides",
    extendedRideFees: "$4 per 30 minutes after the first 30 minutes",
    eBikeFees: "$1 to unlock an ebike",
    terms: "Can be used for 24 hours",
    price: 5,
    cardDefault: null,
    isAutoRenewing: false,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.casual,
    finePrint:
      "Bike must be re-docked within the 30 minute ride to avoid a $4 per 30 minute usage fee.",
  },
  {
    id: 329,
    name: "Explorer",
    displayPrice: "30 days of access for $15",
    baseBenefits: "Unlimited 60-minute rides",
    extendedRideFees: "$4 per 60 minutes after the first 60 minutes",
    eBikeFees: "$1 to unlock an ebike",
    terms: "Renews every 30 days, cancel anytime",
    price: 15,
    cardDefault: false,
    cardCost: 0,
    isAutoRenewing: true,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.member,
    finePrint:
      "Bike must be re-docked within the 60 minute ride to avoid a $4 per 60 minute usage fee.",
  },
  {
    id: 753,
    name: "Downtowner",
    displayPrice: "365 days of access for $125",
    baseBenefits: "Unlimited 60-minute rides",
    extendedRideFees: "$4 per 60 minutes after the first 60 minutes",
    eBikeFees: "$1 to unlock an ebike",
    terms: "Renews every 365 days, cancel anytime",
    price: 125,
    cardDefault: false,
    cardCost: 0,
    isAutoRenewing: true,
    class: "best",
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
    finePrint:
      "Bike must be re-docked within the 60 minute ride to avoid a $4 per 60 minute usage fee.",
  },
  {
    id: 751,
    name: "Explorer Reduced Fare",
    displayPrice: "30 days of access for $5",
    baseBenefits: "Unlimited 60-minute rides",
    extendedRideFees: "$2 per 60 minutes after the first 60 minutes",
    eBikeFees: "$1 to unlock an ebike",
    terms: "Renews every 30 days, cancel anytime",
    discountVerification: 351,
    discountVerificationType: "nevada_rf",
    price: 5,
    cardDefault: false,
    cardCost: 0,
    isAutoRenewing: true,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.member,
    finePrint:
      "Bike must be re-docked within the 60 minute ride to avoid a $2 per 60 minute usage fee.",
  },
  {
    id: 752,
    name: "Downtowner Reduced Fare",
    displayPrice: "365 days of access for $40",
    baseBenefits: "Unlimited 60-minute rides",
    extendedRideFees: "$2 per 60 minutes after the first 60 minutes",
    eBikeFees: "$1 to unlock an ebike",
    terms: "Renews every 365 days, cancel anytime",
    discountVerification: 229,
    discountVerificationType: "nevada_rf",
    price: 40,
    cardDefault: false,
    cardCost: 0,
    isAutoRenewing: true,
    class: "best",
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
    finePrint:
      "Bike must be re-docked within the 60 minute ride to avoid a $2 per 60 minute usage fee.",
  },
];

export const notForPublicPurchaseLasPasses = [
  {
    id: 429,
    name: "RTC Employee Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$4 per 30 minutes thereafter",
    displayPrice: "$0/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 0,
  },
];

export const deprecatedLasPasses = [
  {
    id: 328,
    name: "One Day Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$4 per 30 minutes thereafter",
    displayPrice: "24 hours of access for $5",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 374,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 451,
    name: "3 Day Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$4 per 30 minutes thereafter",
    displayPrice: "$10 for 3 days",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 374,
    duration: PASS_DURATION.threeDays,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 892,
    name: "Life is Beautiful",
    displayPrice: "$10",
    baseBenefits: "Unlimited 30-minute rides",
    extendedRideFees: "$4 per 30 minutes after the first 30 minutes",
    eBikeFees: "$1 to unlock an ebike",
    terms: "Can be used for 7 days",
    price: 10,
    cardDefault: null,
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 374,
    duration: PASS_DURATION.week,
    family: PASS_FAMILY.member,
    finePrint:
      "Bike must be re-docked within the 30 minute ride to avoid a $4 per 30 minute usage fee.",
  },
];
