import { inject, ref, reactive, watchEffect, computed } from "vue";
import { useI18n } from "vue-i18n";
import { fieldIds } from "./fieldIds";

export function useFieldErrorProvider(value, fieldKey, options = {}) {
  const { t } = useI18n();

  const _value = computed(() => {
    if (typeof value === "function") {
      return value();
    } else if (typeof value === "object") {
      return value.value;
    }
    return value;
  });

  const { frontEndErrorsByFieldId, submitted } = inject("accountUpdateError");
  const errorMessage = ref(fieldIds[fieldKey].validate(_value.value, options));
  const blurred = ref(false);

  const label = computed(() => {
    let _label = fieldIds[fieldKey].getLabel({ ...options, t });
    if (errorMessage.value && blurred.value) {
      return `* ${_label}`;
    }
    return _label;
  });

  const message = computed(() => (blurred.value || submitted.value) ? errorMessage.value : undefined);
  const variant = computed(() => errorMessage.value && (blurred.value || submitted.value) ? "danger" : undefined);

  const state = reactive({
    fieldProps: {
      labelFor: fieldIds[fieldKey].id,
      label,
      variant,
      message,
    },
    inputProps: {
      id: fieldIds[fieldKey].id,
      onBlur: (e) => {
        blurred.value = true;
        if (options.emit) {
          options.emit("blur");
        }

        // TODO: IS THIS NECESSARY?
        // Needed because related target button moves by a few pixels after field error message shows
        // and click event is processed after blur event
        // if (e?.relatedTarget && e?.relatedTarget?.tagName === "BUTTON") {
        //   e.relatedTarget.dispatchEvent(new Event("click"));
        // }
      },
    },
    blurred,
    clearErrors: () => frontEndErrorsByFieldId[fieldIds[fieldKey].id] = null,
  });

  watchEffect(() => {
    errorMessage.value = fieldIds[fieldKey].validate(_value.value, options);
    frontEndErrorsByFieldId[fieldIds[fieldKey].id] = errorMessage.value;
  });

  return state;
}