export const user = {
  userId: 1234567,
  userName: "test@example.com",
  // password: null,
  contactEmail: "test@example.com",
  contactPhone: "4407815555",
  homePhone: "",
  firstName: "Test",
  lastName: "User",
  addressStreet: "123 Lombard Street",
  addressCity: "Philadelphia",
  addressState: "PA",
  addressZipCode: "19147",
  isDeleted: false,
  isDelinquent: false,
  // notes: null,
  // isReadOnly: false,
  isAutoRenewing: true,
  optInEmail: true,
  optInSms: true,
  // visibleOnLeaderBoard: false,
  // secretQuestion: null,
  // secretAnswer: null,
  // isBlocked: false,
  created: "2020-09-06T19:02:14Z",
  // additionalField: null,
  addressTypeId: 1,
  addressStreet2: "Unit A",
  subscriptionTypeId: 252,
  // programId: 3,
  // memberProgramName: "Indego",
  subscriptionActivationStatus: "Active",
  // optInPush: false,
  // mobileLanguage: 'en',
  countryId: 236,
  paymentProcessorType: 7,
  // payNearMeOrderUrl: null,
  // failedLoginAttempts: 0,
  // lastFailedLoginAttempt: '2022-10-26T19:21:26.62+00:00',
  // checkoutId: '0',
  // checkoutPin: null,
  // userRoleId: 'RfidMember',
  optInAutoRenewLastUpdateDate: "2022-10-21T00:24:13.61",
  // remainingFreeMinutes: null,
  userHasActiveCards: true,
  isEmailVerified: false,
  hasOpenFailedTransactions: false,
  deletedDate: null,
};