export const paymentProfile = {
  paymentProfileId: 123456,
  paymentProcessorType: "Stripe",
  cardLastFour: "1234",
  cardExpirationMonth: 10,
  cardExpirationYear: 2026,
  cardType: "Visa",
  cardFullName: null,
  cardReference: null,
  cardHash: null,
  transactionCode: null,
  tokenId: null,
};

export const expiredPaymentProfile = { ...paymentProfile, cardExpirationYear: 2020 };

export const creditCallPaymentProfile = { ...paymentProfile, paymentProcessorType: "CreditCall" };

export const payNearMePaymentProfile = {
  ...paymentProfile,
  paymentProcessorType: "PayNearMe",
  cardLastFour: null,
  cardExpirationMonth: null,
  cardExpirationYear: null,
  cardType: "None",
};