export default {
  keyCard: "TAP card",
  userAgreement: {
    unattended: "I agree never to leave a bike unattended when not securely docked in a station.",
    replacement: "I understand that if the bike is lost or stolen, I will be charged a bike replacement fee of up to $2,500.",
    eula: "I am 16 years old or older and I have read and agree to the conditions set forth in the <a href=\"https://bikeshare.metro.net/user-agreement/\" target=\"_blank\">User Agreement.</a>",
  },
  demographicsSurvey: {
    preLinkText: "For a chance to win a 365 Day pass, complete our ",
    linkText: "demographics survey.",
  },
  reducedFare: { 
    sectionDescription: "Discounted passes for Golden State Advantage EBT cardholders, students 16+, seniors 62+, people with ADA-recognized disabilities, and Transit Assistance Program cardholders",
  },
};